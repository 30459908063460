import { useState } from 'react';

import { notification, Radio } from 'antd';
import { useUpdateKycFlowStatusMutation } from 'api/IndividualsApiSlice';
import { BaseButton, StatusTag, useModal } from 'components';
import { GetPersonModel } from 'solstice-types';
import { getFullName } from 'utils';

interface StatusChangeModalProps {
  selectedIndividual: GetPersonModel;
  lastKycId: string;
}

export const StatusChangeModal = ({ selectedIndividual, lastKycId }: StatusChangeModalProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { firstName, lastName, status = '', kycLoginUrl } = selectedIndividual;
  const [updateKycStatus, { isLoading }] = useUpdateKycFlowStatusMutation();

  const { closeModal } = useModal();

  const onRadioChange = (e: any) => {
    setSelectedValue(e.target.value);
  };
  const onCancel = () => {
    closeModal();
  };

  const handleUpdateStatus = async () => {
    if (selectedValue) {
      try {
        await updateKycStatus({ id: lastKycId ?? '', status: selectedValue }).unwrap();
        closeModal();

        notification.success({
          message: 'Status updated successfully',
          className: 'custom-success-message',
          placement: 'top',
        });
      } catch {
        notification.error({
          message: 'Oops! Something went wrong. Please try again.',
          className: 'custom-error-message',
          placement: 'top',
          icon: <></>,
        });
      }
    }
  };
  return (
    <div className="flex flex-col">
      <div className="text-gray-900 font-semibold text-lg mb-1.5">
        {' '}
        {getFullName(firstName, lastName) ?? 'N/A'}
      </div>
      <StatusTag status={status} />
      <span className="text-gray-600 my-[14px]">
        This individual was marked as a Potential Sanctions Match by the ID verification process.
        Please choose whether to mark it as passed or failed.
      </span>
      <Radio.Group
        onChange={onRadioChange}
        value={selectedValue}
        className="flex flex-col font-semibold gap-[14px]"
      >
        <Radio
          className="text-success-700 h-10 bg-gray-50 w-full flex items-center px-2.5"
          value={'ManuallyApproved'}
          checked={selectedValue === 'ManuallyApproved'}
        >
          Mark as Passed
        </Radio>

        <Radio
          checked={selectedValue === 'ManuallyRejected'}
          value={'ManuallyRejected'}
          className="text-error-600 h-10 bg-gray-50 w-full flex items-center px-2.5"
        >
          Mark as Failed
        </Radio>
      </Radio.Group>
      <span className="mt-[14px]">
        You can see the full report by{' '}
        <a
          href={kycLoginUrl}
          target="_blank"
          className="font-medium text-blue-400"
          rel="noreferrer"
        >
          clicking here.
        </a>
      </span>

      <div className="gap-2.5 flex justify-between w-full pt-8">
        <BaseButton className="min-w-[112px] secondary-button" onClick={onCancel}>
          Cancel
        </BaseButton>

        <BaseButton
          loading={isLoading}
          className="primary-button text-white"
          onClick={handleUpdateStatus}
          disabled={!selectedValue}
        >
          Confirm
        </BaseButton>
      </div>
    </div>
  );
};
