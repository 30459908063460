export const trustTypesOptions = [
  { value: '15ab85a4-d5dd-4b68-9fdc-3711233b2e67', label: 'Ultimate Holding Company' },
  { value: '15f6a75c-990a-414f-932b-c86ce5ee4893', label: 'Consortium' },
  { value: '2161c01a-ff7e-4f9a-8f60-34c1927f32ad', label: 'Holding Company' },
  { value: '3684bb62-e97f-41a6-bdc3-04a690e3e40b', label: 'Joint Venture' },
  { value: '3a3eac7b-7e85-44a9-83c4-c1f02045c7e0', label: 'Partnership' },
  { value: '4606f417-e717-48bc-8447-dae39f532658', label: 'Parent Company' },
  { value: '58f40056-32ee-40ae-ac96-daa3c56dc1fd', label: 'Investor / VC' },
  { value: '61384e01-ca97-40de-b1f4-4d862893ebf9', label: 'Minority Ownerships' },
  { value: '63396823-6262-4658-97f8-af95ff56b104', label: 'Other Business Type' },
  { value: '82cf14c9-6a34-4cc1-b789-99003c109c7e', label: 'Equity Alliance' },
  { value: '97132380-5621-440a-aed7-eaec0c94ec2c', label: 'Subsidiary Company' },
  { value: 'a3776363-c172-4938-8eab-cacd4bb43de1', label: 'Association' },
  { value: 'a56e16f7-24a9-4dd8-bb1a-1b4d220d1472', label: 'Cross-Ownership' },
  { value: 'c1596449-0676-423c-8707-f9d9fb4c1e7e', label: 'Cooperative' },
  { value: 'c59653ea-9866-4142-b90c-89564028d8a9', label: 'Merger' },
];

export const relationshipOptions = [
  {
    id: '349776df-a736-40c3-b8ce-682c228183f5',
    label: 'Creator',
  },
  {
    id: '00981a34-d569-477e-81b1-371255f69b0c',
    label: 'Sibling-in-law',
  },
  {
    id: '255dbf7b-b32d-4725-b68c-1af5046059ba',
    label: 'Child',
  },
  {
    id: '3122c33c-ca01-43f1-b1b8-0f1aefc604fc',
    label: 'Spouse / De Facto',
  },
  {
    id: '3141d14e-d2b2-43be-8f21-79bb5821821c',
    label: 'Nephew/Neice',
  },
  {
    id: '3c573e53-83da-4ec0-a280-8d81c9a5d2b6',
    label: 'Other - Family Relationship',
  },
  {
    id: '44066de6-61a2-4bf8-8a0d-f707a49b52bc',
    label: 'Step-parent',
  },
  {
    id: '4fd40f71-62d5-496b-a489-c978f92aa501',
    label: 'Parent',
  },
  {
    id: '556ed742-557e-4715-9a96-a0b21b55b55d',
    label: 'Other - Professional Relationship',
  },
  {
    id: '83cfb2b8-8e28-4001-a776-fe22ad568d99',
    label: 'Child-in-Law',
  },
  {
    id: '89d97f57-7caf-428c-a39e-2a114bc356f8',
    label: 'Step-sibling',
  },
  {
    id: '8a86f837-bf2c-49a8-95b3-f05a941882d7',
    label: '1st Cousin',
  },
  {
    id: '8ab0a623-f9d0-4468-9304-ce2dd081a9e5',
    label: 'Grandchild',
  },
  {
    id: 'a5a73928-6795-46ee-81d3-b37c0d6104b3',
    label: 'Aunt/Uncle',
  },
  {
    id: 'c2b70d3b-6992-4b3e-98ab-ff8732e9bad5',
    label: 'Step-child',
  },
  {
    id: 'c48b16e2-2102-4830-9388-8cac0018dae2',
    label: 'Half-sibling',
  },
  {
    id: 'c4e94116-b72c-4c6c-983f-db105d1b267e',
    label: 'Other - Legal Relationship',
  },
  {
    id: 'cdecec3e-a9d0-41a4-9f4a-12428f83ba3b',
    label: 'Parent-in-Law',
  },
  {
    id: 'ce98200a-7851-4b13-a0ee-f9d942b6fdc1',
    label: 'Sibling',
  },
  {
    id: 'd57084c9-75ea-465a-adaa-bc3668e0cb25',
    label: 'Other - Political Relationship',
  },
  {
    id: 'f954f15d-c8db-4841-aa9a-4fb9ac728275',
    label: 'Grandparent',
  },
];
