import React, { forwardRef } from 'react';

import type { InputRef } from 'antd';
import { AutoComplete, Input } from 'antd';
import type { AutoCompleteProps } from 'antd/es/auto-complete';

import './SearchInputs.scss';

type CustomOptionType = {
  value?: string | null;
  label: React.ReactNode | null;
};

interface SearchInputProps extends Omit<AutoCompleteProps<string, CustomOptionType>, 'options'> {
  options?: CustomOptionType[];
  onSearch?: (value: string) => void;
  inputClassName?: string;
  autoFocus?: boolean;
}

export const SearchInput = forwardRef<InputRef, SearchInputProps>(
  // eslint-disable-next-line react/prop-types
  ({ options, onSearch, inputClassName, autoFocus, ...rest }, ref) => {
    return (
      <AutoComplete<string, CustomOptionType>
        options={options}
        onSearch={onSearch}
        className="placeholder-gray-700 w-full"
        autoFocus={autoFocus}
        {...rest}
      >
        <Input className={`h-10 ${inputClassName}`} />
      </AutoComplete>
    );
  }
);

SearchInput.displayName = 'SearchInput';
