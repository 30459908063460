import { apiPrimeSlice } from 'app-redux/store';
import {
  CreatePersonModel,
  GetPersonModel,
  GetPersonWithRolesModel,
  ProblemDetails,
  SearchByEmailModel,
} from 'solstice-types';

const individualsApiSlice = apiPrimeSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIndividuals: builder.query<ProblemDetails[], void>({
      query: () => ({
        url: '/persons',
        method: 'GET',
      }),
      providesTags: ['Individuals'],
    }),

    getIndividualById: builder.query<GetPersonWithRolesModel, string>({
      query: (id) => ({
        url: `/persons/${id}`,
        method: 'GET',
      }),
      providesTags: ['IndividualsById'],
    }),

    addIndividual: builder.mutation<
      CreatePersonModel,
      {
        newIndividual: Partial<CreatePersonModel[]>;
        isGroup: boolean;
      }
    >({
      query: ({ newIndividual }) => ({
        url: '/persons',
        method: 'POST',
        body: newIndividual,
      }),
      invalidatesTags: (result, error, { isGroup }) => (isGroup ? ['Groups'] : ['Individuals']),
    }),

    searchIndividual: builder.mutation<GetPersonModel[], any>({
      query: (searchTerm) => ({
        url: '/persons/search',
        method: 'POST',
        body: searchTerm,
      }),
    }),

    searchIndividualByEmail: builder.mutation<GetPersonModel, SearchByEmailModel>({
      query: (email) => ({
        url: '/persons/search-by-email',
        method: 'POST',
        body: email,
      }),
    }),

    updateKycFlowStatus: builder.mutation<any, { id: string; status: string }>({
      query: ({ id, status }) => ({
        url: `/kyc-flows/${id}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['IndividualsById', 'GetEntityById'],
    }),
  }),
});

export const {
  useGetIndividualsQuery,
  useAddIndividualMutation,
  useSearchIndividualMutation,
  useGetIndividualByIdQuery,
  useSearchIndividualByEmailMutation,
  useUpdateKycFlowStatusMutation,
} = individualsApiSlice;
