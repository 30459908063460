import { BrowserRouter as Router } from 'react-router-dom';

import { Main } from 'main';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

export const App = () => (
  <Router>
    <Main />
  </Router>
);
