import { Dispatch, Fragment, SetStateAction } from 'react';
import { Tab as HeadlessTab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

export type TabType = {
  id?: string;
  tabButton: string;
  tabContent: React.ReactNode;
  testId: string;
  onTabClick?: () => void;
  hasError?: boolean;
};

interface ITabProps {
  tabs: TabType[];
  activeTabIndex?: number;
  controlled?: boolean;
  selectedIndex?: number;
  setSelectedIndex?: Dispatch<SetStateAction<number>>;
  panelStyle?: string;
}

export const Tab = ({
  tabs,
  activeTabIndex = 0,
  controlled = false,
  selectedIndex,
  setSelectedIndex,
  panelStyle,
}: ITabProps) => {
  const tabButtonClassName = (selected: boolean) =>
    twMerge(
      classNames('font-medium px-2 py-2.5 text-[14px] outline-none mx-2 first:ml-0', {
        'text-primary-600 border-b-2 border-primary-600 font-semibold': selected,
        'text-gray-500': !selected,
      })
    );

  return (
    <TabGroup
      defaultIndex={controlled ? undefined : activeTabIndex}
      selectedIndex={controlled ? selectedIndex : undefined}
      onChange={controlled ? setSelectedIndex : undefined}
    >
      <TabList>
        <div className="border-b border-solid w-full border-gray-200">
          {tabs.map(({ id, tabButton, testId, onTabClick }) => (
            <HeadlessTab key={id ?? tabButton} as={Fragment}>
              {({ selected }) => (
                <button
                  data-testid={`${testId}-tab-button`}
                  type="button"
                  onClick={onTabClick}
                  className={tabButtonClassName(selected)}
                >
                  <div className="flex justify-center items-center gap-[10px]">{tabButton}</div>
                </button>
              )}
            </HeadlessTab>
          ))}
        </div>
      </TabList>
      <TabPanels>
        {tabs.map(({ id, tabContent, tabButton }) => (
          <TabPanel
            className={twMerge(classNames('focus-visible:outline-0', panelStyle))}
            key={id ?? tabButton}
          >
            {tabContent}
          </TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};
