import React from 'react';

import { PaginationProps, Spin, Table, TablePaginationConfig } from 'antd';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import { v4 as uuidv4 } from 'uuid';

import './TableComponent.scss';

export type TableData = {
  id?: string | number;
  [key: string]: string | number | boolean | null | undefined;
};

interface TableComponentProps<T extends TableData> {
  columns: ColumnsType<any>;
  dataSource?: any[];
  className?: string;
  hasPagination?: boolean;
  noDataMessage?: string;
  pagination?: {
    current: number;
    pageSize: number;
    total: number;
  };
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => void;
  onRow?: (record: any, index?: number) => React.HTMLAttributes<HTMLElement>;
  isLoading?: boolean;
  tableScroll?: any;
  expandable?: any;
}

const CustomPagination: PaginationProps = {
  showSizeChanger: false,
  showQuickJumper: false,
  showTotal: (total, range) => (
    <div className="text-gray-900 font-bold">
      {range[0]}-{range[1]} <span className="font-normal">of</span> {total}{' '}
      <span className="text-gray-600 font-normal">Items</span>
    </div>
  ),
  className: 'custom-pagination',
};

export function TableComponent<T extends TableData>({
  columns,
  dataSource,
  pagination,
  onChange,
  className,
  hasPagination = true,
  onRow,
  isLoading = false,
  tableScroll,
  expandable,
  noDataMessage = 'No data available',
}: TableComponentProps<T>) {
  return (
    <div className="table-container">
      <Table<T>
        columns={columns}
        loading={{ children: <Spin className="bg-white" />, spinning: isLoading }}
        dataSource={dataSource}
        rowKey={(record) => record?.id?.toString() || uuidv4()}
        onChange={onChange}
        locale={{
          emptyText: <div className="text-gray-500">{noDataMessage} </div>,
        }}
        className={`overflow-y-auto ${className}`}
        onRow={onRow}
        scroll={tableScroll}
        expandable={expandable}
        pagination={
          hasPagination
            ? {
                ...CustomPagination,
                ...pagination,
                position: ['bottomLeft'],
                pageSize: pagination?.pageSize || 20,
              }
            : false
        }
      />
    </div>
  );
}
