import { BaseButton } from 'components';

import './TableFilters.scss';

interface TableFiltersProps {
  activeFilter: string;
  handleFilterClick: (filter: string) => void;
  data: any[];
}

export const TableFilters = ({ activeFilter, handleFilterClick, data }: TableFiltersProps) => {
  const getStatusCount = (status: string) => {
    return data?.filter((group) => (group?.status ?? group?.kycStatus) === status).length || 0;
  };

  const bgFilterColor = activeFilter === '' ? 'bg-gray-100' : '';

  return (
    <div>
      <BaseButton
        className={`min-w-[90px] button-filter ${bgFilterColor}`}
        onClick={() => handleFilterClick('')}
      >
        All <div className="bg-gray-100 text-gray-600 px-2 rounded-full">{data?.length ?? 0}</div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter ${activeFilter === 'ManuallyApproved' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('ManuallyApproved')}
      >
        Manually Passed{' '}
        <div className="bg-success-50 text-success-700 px-2 rounded-full">
          {getStatusCount('ManuallyApproved')}
        </div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter ${activeFilter === 'ManuallyRejected' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('ManuallyRejected')}
      >
        Manually Fail{' '}
        <div className="border-grayModern-300 bg-error-50 text-error-500 px-2 rounded-full">
          {getStatusCount('ManuallyRejected')}
        </div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter ${activeFilter === 'SanctionsMatch' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('SanctionsMatch')}
      >
        Sanctions Match{' '}
        <div className="border-grayModern-300 bg-grayModern-100 text-grayModern-600 px-2 rounded-full">
          {getStatusCount('SanctionsMatch')}
        </div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter ${activeFilter === 'PEEP Match' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('PEEP Match')}
      >
        PEP Match{' '}
        <div className="bg-warning-50 text-warning-700 px-2 rounded-full">
          {getStatusCount('PEEP Match')}
        </div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter ${activeFilter === 'ReviewKyc' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('ReviewKyc')}
      >
        Review KYC{' '}
        <div className="bg-warning-50 text-warning-700 px-2 rounded-full">
          {getStatusCount('ReviewKyc')}
        </div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter ${activeFilter === 'Verified' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('Verified')}
      >
        Verified{' '}
        <div className="bg-success-50 text-success-700 px-2 rounded-full">
          {getStatusCount('Verified')}
        </div>
      </BaseButton>

      <BaseButton
        className={`min-w-fit button-filter min-w-fit ${activeFilter === 'InProgress' ? 'bg-gray-100' : ''}`}
        onClick={() => handleFilterClick('InProgress')}
      >
        In Progress{' '}
        <div className="bg-blue-100 text-blue-800 px-2 rounded-full">
          {getStatusCount('InProgress')}
        </div>
      </BaseButton>
    </div>
  );
};
