import Avatar from 'antd/es/avatar/avatar';
import { GetPersonModel } from 'solstice-types';
import { getUserInitials } from 'utils/utils';

interface UserInitialsCircleProps {
  data: GetPersonModel;
  className?: string;
}

export const UserInitialsCircle = ({
  data,
  className = 'rounded-full min-w-[34px] min-h-[34px] w-[34px] h-[34px] text-xs',
}: UserInitialsCircleProps): JSX.Element => {
  const initials = getUserInitials(data);

  return initials ? (
    <div
      className={`flex bg-gray-50 p-1 text-gray-500 font-semibold justify-around items-center ${className}`}
    >
      {initials}
    </div>
  ) : (
    <Avatar size={32} src="" />
  );
};
