import { setCurentStep } from 'api/client-side-slices/DesignSlice';
import { useAppDispatch, useAppSelector } from 'app-redux/hooks';
import { BaseButton, ModalComponent, useModal } from 'components';
import { useDrawer } from 'components/Drawer/Drawer';

import './components/IndividualFooter.scss';

interface IndividualDrawerFooterProps {
  confirmButtonText?: () => 'Start Verification' | 'Continue' | string;
  handleSave?: () => void;
  hasSteps?: boolean;
  handlePreviousStep?: () => void;
  isLoading?: boolean;
  isStartDisabled?: boolean;
  hideFooter?: boolean;
}

export const IndividualDrawerFooter = ({
  confirmButtonText = () => 'Start Verification',
  handleSave,
  hasSteps,
  handlePreviousStep,
  isLoading,
  isStartDisabled,
  hideFooter,
}: IndividualDrawerFooterProps) => {
  const { closeDrawer } = useDrawer();
  const { openModal, closeModal } = useModal();
  const dispatch = useAppDispatch();
  const buttonStyle = isStartDisabled ? '' : 'primary-button font-semibold text-white';
  const { currentStep } = useAppSelector((state) => state.design);
  const modalBodyText =
    'You are about to cancel and all progress will be lost. Any data you entered for the new company will be lost. Are you sure you want to proceed?';

  const handleCancel = () => {
    if (currentStep === 2) {
      handleDisplayModal();
    } else {
      closeDrawer();
    }
  };

  const handleDisplayModal = () => {
    openModal(
      <ModalComponent
        bodyContent={modalBodyText}
        onCancel={handleCancel}
        onUpdate={() => {
          closeModal();
          closeDrawer();
          dispatch(setCurentStep(0));
        }}
        buttonText="Confirm"
      />,
      'Are You Sure?'
    );
  };

  return (
    <div className="flex justify-between">
      <BaseButton className="secondary-button" onClick={handleCancel}>
        Cancel
      </BaseButton>

      {!hideFooter && (
        <div className="flex gap-2.5">
          {hasSteps && (
            <BaseButton
              className="font-semibold text-gray-700 min-w-[143px]"
              onClick={handlePreviousStep}
            >
              Previous Step
            </BaseButton>
          )}

          <BaseButton
            className={`min-w-fit px-[18px] ${buttonStyle}`}
            loading={isLoading}
            onClick={handleSave}
            disabled={isStartDisabled}
          >
            {confirmButtonText()}
          </BaseButton>
        </div>
      )}
    </div>
  );
};
