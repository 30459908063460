// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-filter {
  min-width: fit-content;
  border-radius: 0px;
  border-right-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(205 213 223 / var(--tw-border-opacity))
}
.button-filter:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}
.button-filter:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-right-width: 1px
}
.button-filter:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 246 / var(--tw-bg-opacity));
  color: #364152 !important;
  background-color: #eef2f6 !important;
  border-color: #cdd5df !important
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/TableFilters.scss"],"names":[],"mappings":"AACE;EAAA,sBAAA;EAAA,kBAAA;EAAA,uBAAA;EAAA,sBAAA;EAAA;AAAA;AAGE;EAAA,8BAAA;EAAA;AAAA;AAIA;EAAA,+BAAA;EAAA,kCAAA;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA,yDAAA;EACA,yBAAA;EACA,oCAAA;EACA;AAHA","sourcesContent":[".button-filter {\n  @apply border-grayModern-300 rounded-none min-w-fit border-r-0;\n\n  &:first-child {\n    @apply rounded-l-lg;\n  }\n\n  &:last-child {\n    @apply rounded-r-lg border-r;\n  }\n  &:hover {\n    @apply bg-grayModern-100;\n    color: #364152 !important;\n    background-color: #eef2f6 !important;\n    border-color: #cdd5df !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
