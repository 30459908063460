import { Navigate, Route, Routes } from 'react-router-dom';

import { DrawerProvider } from 'components/Drawer';
import { ModalProvider } from 'components/Modal';
import { CompanyDetails, IndividualDetails, KnowYourCustomer, Layout } from 'pages';
import { Billing } from 'pages/Billing/Billing';
import { AuditLog } from 'pages/KnowYourCustomer/AuditLog/AuditLog';
import { routes } from 'routes';
import { getCookieValue } from 'utils/helpers';
import { isLocalhost } from 'utils';

export const Main = () => {
  const { home } = routes;
  const { knowYourCustomer, companyDetails, individualDetails, auditLog, billing } = home;

  const token = getCookieValue('token');

  if (!token) {
    const complianceAppHost = process.env.REACT_APP_LEGACY_COMPLIANCE_APP_HOST;

    const navigateToLink = isLocalhost()
      ? `http://compliance.localhost.com:3000`
      : complianceAppHost;

    window.location.href = `${navigateToLink}/#/login`;
  }

  return (
    <ModalProvider>
      <DrawerProvider>
        <Layout>
          <Routes>
            <Route path={knowYourCustomer} element={<KnowYourCustomer />} />
            <Route path={billing} element={<Billing />} />
            <Route path={companyDetails} element={<CompanyDetails />} />
            <Route path={individualDetails} element={<IndividualDetails />} />
            <Route path={auditLog} element={<AuditLog />} />
            <Route path="/" element={<Navigate to={knowYourCustomer} replace />} />
            <Route path="*" element={<Navigate to={knowYourCustomer} replace />} />
          </Routes>
        </Layout>
      </DrawerProvider>
    </ModalProvider>
  );
};
