import animationLoadingJson from '../assets/animations/Animation - UBO.json';
import avatar from '../assets/icons/Avatar.png';
import avatarMale from '../assets/icons/Avatar-male.png';
import buildingIcon from '../assets/icons/building-03.svg';
import chevronDownIcon from '../assets/icons/chevron-down.svg';
import chevronLeftIcon from '../assets/icons/chevron-left-pagination.svg';
import chevronRightIcon from '../assets/icons/chevron-right.svg';
import chevronUpIcon from '../assets/icons/chevron-up.svg';
import deleteIcon from '../assets/icons/delete.svg';
import dividerVerticalIcon from '../assets/icons/divider-vertical.svg';
import dotsGridIcon from '../assets/icons/dots-grid.svg';
import helipCircleIcon from '../assets/icons/help-circle.svg';
import plusSquareIcon from '../assets/icons/plus-square.svg';
import primelogo from '../assets/icons/prime-logo.png';
import userCheckIcon from '../assets/icons/user-check-01.svg';
import userPlusIcon from '../assets/icons/user-plus-01.svg';
import { default as usersIcon } from '../assets/icons/users-plus.svg';

export const DIVIDER_VERTICAL_ICON = dividerVerticalIcon;
export const CHEVRON_DOWN_ICON = chevronDownIcon;
export const CHEVRON_UP_ICON = chevronUpIcon;
export const CHEVRON_LEFT_ICON = chevronLeftIcon;
export const CHEVRON_RIGHT_ICON = chevronRightIcon;
export const BUILDING_ICON = buildingIcon;
export const USER_PLUS_ICON = userPlusIcon;
export const USERS_ICON = usersIcon;
export const AVATAR_ICON = avatar;
export const DELETE_ICON = deleteIcon;
export const DOTS_GRID_ICON = dotsGridIcon;
export const HELP_CIRCLE_ICON = helipCircleIcon;
export const PRIME_LOGO = primelogo;
export const USER_CHECK_ICON = userCheckIcon;
export const AVATAR_MALE_ICON = avatarMale;
export const PLUS_SQUARE_ICON = plusSquareIcon;

export const ANIMATION_LOADING_JSON = animationLoadingJson;
