import { useEffect, useState } from 'react';
import ArrowUpIcon from '@untitled-ui/icons-react/build/esm/ArrowUp';
import CalendarDate from '@untitled-ui/icons-react/build/esm/CalendarDate';
import HomeLine from '@untitled-ui/icons-react/build/esm/HomeLine';

import { DatePicker, Spin } from 'antd';
import { useGetBillingDataMutation, useGetBillingTransactionsQuery } from 'api/EntitiesApiSlice';
import { BaseButton, BaseSelect, Breadcrumb, TableComponent, TableSearch } from 'components';
import dayjs from 'dayjs';
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { billingColumns } from 'utils/constants';
import { getCookieValue } from 'utils/helpers';
import * as XLSX from 'xlsx';

export const Billing = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<any>(null);
  const [selectedEntityType, setSelectedEntityType] = useState<string>('all');
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const [fetchBillingData, { data, isLoading }] = useGetBillingDataMutation();
  const { data: transactionsData } = useGetBillingTransactionsQuery();

  useEffect(() => {
    const lastMonth = dayjs().subtract(1, 'month').startOf('month');
    setSelectedMonth(lastMonth);

    fetchBillingData({
      pageSize: 10220,
      pageNumber: 1,
    });
  }, []);

  const isLastMonth = (date: dayjs.Dayjs) => {
    const lastMonth = dayjs().subtract(1, 'month').startOf('month');
    return date.isSame(lastMonth, 'month');
  };

  const customFormat = (value: dayjs.Dayjs | null) => {
    if (value && isLastMonth(value)) {
      return 'Last Month';
    }
    return value ? value.format('MMM YYYY') : '';
  };

  const breadcrumbItems = [
    {
      title: <HomeLine className="h-5 w-5 text-gray-500" />,
      href: '/',
    },
    {
      title: 'Billing',
      className: 'font-semibold text-primary-600',
    },
  ];

  const exportToExcel = () => {
    if (!data) return;

    const processedData = data.map((item) => {
      const { updatedAt, ...rest } = item;

      const formattedDate = dayjs(updatedAt).format('YYYY-MM-DD hh:mm A');

      const isUserGuest = getCookieValue('roleId') === 4;

      return {
        ...rest,
        updatedAt: formattedDate,
        client: isUserGuest ? '' : { client: item.client },
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(processedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions Data');

    XLSX.writeFile(workbook, 'billing_data.xlsx');
  };

  const onChange = (value: string) => {
    setSearchValue(value);
  };

  const handleDateChange = (date: any) => {
    if (date) {
      setSelectedMonth(date);
    } else {
      setSelectedMonth(null);
    }
  };

  const handleEntityTypeChange = (value: string) => {
    setSelectedEntityType(value);
  };

  const filteredData = data?.filter((item) => {
    const itemDate = dayjs(item.updatedAt);
    const isSameMonth = selectedMonth ? itemDate.isSame(selectedMonth, 'month') : true;
    const isEntityMatch = selectedEntityType === 'all' || item.entityType === selectedEntityType;
    const searchMatch = searchValue
      ? item.entityName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.entityType?.toLowerCase().includes(searchValue.toLowerCase())
      : true;

    return isSameMonth && isEntityMatch && searchMatch;
  });
  const actionTypeOptions = [
    { label: 'All entities', value: 'all' },
    { label: 'Group', value: 'Group' },
    { label: 'Company', value: 'Company' },
    { label: 'Trust/Other', value: 'Trust' },
  ];

  const clientOptions = [
    { label: 'All Clients', value: 'all' },
    // Additional client options to be added WIP TODO
  ];

  const totalTransactions =
    filteredData?.reduce((acc, transaction) => {
      const uboFlowCount = transaction.uboFlowCount || 0;
      const kycFlowCount = transaction.kycFlowCount || 0;
      return acc + uboFlowCount + kycFlowCount;
    }, 0) || 0;

  const renderCustomTooltip = ({ payload }: TooltipProps<any, string>) => {
    if (!payload || !payload.length) return null;
    const transactionsCount = payload[0].payload.totalValue ?? 0;
    const transactionDate = payload[0].payload.yearMonth;
    return (
      <div className="bg-white font-medium p-2 shadow-sm border border-gray-200 rounded">
        <p>Month: {transactionDate}</p>
        <span>
          Transactions: <span className="text-green-500">{transactionsCount}</span>
        </span>
      </div>
    );
  };

  const isUserGuest = getCookieValue('roleId') === 4;

  if (isLoading) {
    return <Spin fullscreen className="translate-x-16" />;
  }

  return (
    <div className="pt-8 h-screen">
      <div className="flex flex-col px-[22.5px] bg-white pb-7">
        <div className="w-full pb-5">
          <Breadcrumb items={breadcrumbItems} individualName="" />
        </div>

        <div className="flex items-center justify-between w-full">
          <span className="text-2xl text-grayModern-800 font-bold">Billing</span>
          <BaseButton className="secondary-button min-w-fit" onClick={exportToExcel}>
            Export as CSV
          </BaseButton>
        </div>

        <div className="flex justify-between p-6 border rounded-xl border-gray-200 mt-6 shadow-sm h-[176px]">
          <div className="flex flex-col gap-5">
            <span className="text-gray-900 font-semibold">Total Transactions</span>
            <span className="text-gray-900 font-bold text-4xl">{totalTransactions}</span>
            <span className="flex gap-1 text-success-700 font-medium">
              <ArrowUpIcon className="" /> 20% <p className="text-gray-600">vs last month</p>
            </span>
          </div>

          <div className="w-1/2 flex">
            <ResponsiveContainer width="100%" height="20%" className="mt-20">
              <LineChart data={transactionsData}>
                <Tooltip content={renderCustomTooltip} />
                <Line
                  type="monotone"
                  dataKey="totalValue"
                  stroke="#26A376"
                  strokeWidth={1.5}
                  dot={false}
                  activeDot={{
                    r: 4,
                    fill: '#FFFFFF',
                    stroke: '#26A376',
                    strokeWidth: 2,
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 h-full p-2.5 text-sm font-medium">
        <div className="bg-white h-fit rounded-lg p-5">
          <div className="flex items-center justify-between pb-5">
            <span className="mr-2.5 text-gray-600 ">Transactions</span>
            <div className="flex gap-2.5">
              <TableSearch
                onChange={onChange}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
              />

              <BaseSelect
                options={clientOptions}
                className="w-48 h-10"
                defaultValue="Client"
                isUserGuest={isUserGuest}
              />

              <BaseSelect
                options={actionTypeOptions}
                className="w-48 h-10"
                defaultValue="All Entities"
                onChange={handleEntityTypeChange}
              />

              <DatePicker
                className="w-48 h-10"
                suffixIcon={<CalendarDate className="h-5 text-gray-700" />}
                onChange={handleDateChange}
                value={selectedMonth}
                placeholder="Select month"
                picker="month"
                format={customFormat}
                maxDate={dayjs().subtract(0, 'month').endOf('month')}
                minDate={dayjs('1900-01-01')}
              />
            </div>
          </div>

          <TableComponent
            columns={billingColumns}
            dataSource={filteredData}
            className="billing-table"
          />
        </div>
      </div>
    </div>
  );
};
