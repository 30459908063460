import { apiPrimeSlice } from 'app-redux/store';
import {
  CreateEntityUboFlowModel,
  GetEntityUboFlowModel,
  UboGetReportResponse,
} from 'solstice-types';

const uboFlowsApiSlice = apiPrimeSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUboFlow: builder.mutation<GetEntityUboFlowModel, CreateEntityUboFlowModel>({
      query: ({ entityIdentifier, identifierType }) => ({
        url: '/ubo-flows',
        method: 'POST',
        body: { entityIdentifier, identifierType },
      }),
    }),

    getUboFlowById: builder.mutation<UboGetReportResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/ubo-flows/${id}`,
        method: 'POST',
        body: id,
      }),
    }),
  }),
});

export const { useGetUboFlowByIdMutation, useGetUboFlowMutation } = uboFlowsApiSlice;
