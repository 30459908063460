import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useGetIndividualsQuery, useSearchIndividualMutation } from 'api/IndividualsApiSlice';
import { TableComponent } from 'components';
import { useDebounce } from 'use-debounce';
import { Status } from 'utils';
import { individualColumns } from 'utils/constants';
import { TableHeader } from './components/TableHeader';

export type IndividualTableData = {
  firstName: ReactNode;
  lastName: ReactNode;
  dateOfBirth: ReactNode;
  status?: Status;
  assignedTo: ReactNode;
  lastUpdated: ReactNode;
  location: string;
  id: number;
};
export const IndividualsTab = () => {
  const [searchValue, setSearchValue] = useState('');
  const [activeFilter, setActiveFilter] = useState<string>('');
  const [hasSearched, setHasSearched] = useState(false);

  const navigate = useNavigate();

  const { data: individualsData } = useGetIndividualsQuery();
  const [fetchFilters, { data: filtersData, isLoading }] = useSearchIndividualMutation();

  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const fetchFilteredData = (searchTerm: string, status: string) => {
    setHasSearched(searchTerm !== '' || status !== '');
    fetchFilters({
      searchTerm,
      status,
      pageSize: 99999999,
      pageNumber: 1,
    });
  };

  const handleFilterClick = (filter: string) => {
    setActiveFilter(filter);
    fetchFilteredData(debouncedSearchValue, filter);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleRowClick = (record: IndividualTableData) => {
    navigate(`/individual-details/${record.id}`);
  };

  const sortedIndividualsData = filtersData?.slice().sort((a, b) => {
    return (
      new Date(b.statusUpdatedAt ?? '').getTime() - new Date(a.statusUpdatedAt ?? '').getTime()
    );
  });

  const getNoDataMessage = () => {
    if (!hasSearched) {
      return 'You have no KYC verifications started. Start your first KYC verification today!';
    }
    return 'No records found';
  };

  useEffect(() => {
    if (searchValue === '') {
      fetchFilteredData('', activeFilter);
    }
  }, [searchValue]);

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      fetchFilteredData(debouncedSearchValue, activeFilter);
    }
  }, [debouncedSearchValue, activeFilter]);
  return (
    <div className="mb-[80px]">
      <TableHeader
        activeFilter={activeFilter}
        data={individualsData}
        handleFilterClick={handleFilterClick}
        onChange={handleSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <TableComponent
        columns={individualColumns}
        noDataMessage={getNoDataMessage()}
        dataSource={sortedIndividualsData}
        className="entities-table"
        isLoading={isLoading}
        onRow={(record: IndividualTableData) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};
