import SearchIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import CloseIcon from '@untitled-ui/icons-react/build/esm/X';

import { BaseButton } from 'components/Buttons';
import { SearchInput } from 'components/Inputs';

interface TableSearchProps {
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  onChange?: (value: string) => void;
  className?: string;
  isSearchOpen?: boolean;
  setIsSearchOpen?: (value: boolean) => void;
}

export const TableSearch = ({
  searchValue,
  onChange,
  setSearchValue,
  className,
  isSearchOpen,
  setIsSearchOpen,
}: TableSearchProps) => {
  const toggleSearch = () => {
    setIsSearchOpen?.(!isSearchOpen);
    setSearchValue && setSearchValue('');
  };

  return (
    <div className={`${className} relative flex rounded-lg`}>
      {isSearchOpen && (
        <SearchInput
          value={searchValue}
          onChange={onChange}
          className="w-full transition-all search-input"
          placeholder="Search..."
          inputClassName="rounded-r-none h-10"
        />
      )}

      <BaseButton
        className={`secondary-button  ${
          isSearchOpen
            ? 'rounded-l-none min-w-[52px] border border-primary-600 hover:border-primary-600'
            : 'min-w-9 hover:border-[#cdd5df] border-transparent'
        } p-0`}
        onClick={toggleSearch}
      >
        {isSearchOpen ? <CloseIcon className="h-5 w-5" /> : <SearchIcon className="h-5 w-5" />}
      </BaseButton>
    </div>
  );
};
