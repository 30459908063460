// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button {
  height: 2.5rem;
  min-width: fit-content;
  border-radius: 0px;
  border-radius: 0.5rem;
  border-right-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(37 98 169 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(37 98 169 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}
.primary-button:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}
.primary-button:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-right-width: 1px
}
.primary-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 246 / var(--tw-bg-opacity));
  color: #ffffff !important;
  background-color: #1d5699 !important;
  border-color: #1d5699 !important
}

.secondary-button {
  border-radius: 0px;
  border-radius: 0.5rem;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity))
}
.secondary-button:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}
.secondary-button:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-right-width: 1px
}
.secondary-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 246 / var(--tw-bg-opacity));
  color: #364152 !important;
  background-color: #eef2f6 !important;
  border-color: #cdd5df !important
}

.link-button {
  min-width: fit-content;
  border-radius: 0px;
  border-radius: 0.5rem;
  border-right-width: 1px;
  border-style: none;
  padding: 0px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0) !important;
  color: #2562a9 !important
}
.link-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 246 / var(--tw-bg-opacity));
  color: #3b72b2 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/BaseButton.scss"],"names":[],"mappings":"AACE;EAAA,cAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA,qBAAA;EAAA,uBAAA;EAAA,sBAAA;EAAA,uDAAA;EAAA,kBAAA;EAAA,uDAAA;EAAA,oBAAA;EAAA;AAAA;AAGE;EAAA,8BAAA;EAAA;AAAA;AAIA;EAAA,+BAAA;EAAA,kCAAA;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA,yDAAA;EACA,yBAAA;EACA,oCAAA;EACA;AAHA;;AAQF;EAAA,kBAAA;EAAA,qBAAA;EAAA,uBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,oBAAA;EAAA;AAAA;AAEE;EAAA,8BAAA;EAAA;AAAA;AAIA;EAAA,+BAAA;EAAA,kCAAA;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA,yDAAA;EACA,yBAAA;EACA,oCAAA;EACA;AAHA;;AAOF;EAAA,sBAAA;EAAA,kBAAA;EAAA,qBAAA;EAAA,uBAAA;EAAA,kBAAA;EAAA,YAAA;EACA,+CAAA;EACA;AAFA;AAIE;EAAA,kBAAA;EAAA,yDAAA;EACA,yBAAA;EACA,oCAAA;EACA;AAHA","sourcesContent":[".primary-button {\n  @apply border-primary-600 bg-primary-600 rounded-none min-w-fit border-r-0 h-10 text-white rounded-lg;\n\n  &:first-child {\n    @apply rounded-l-lg;\n  }\n\n  &:last-child {\n    @apply rounded-r-lg border-r;\n  }\n  &:hover {\n    @apply bg-grayModern-100;\n    color: #ffffff !important;\n    background-color: #1d5699 !important;\n    border-color: #1d5699 !important;\n  }\n}\n\n.secondary-button {\n  @apply border-gray-300 rounded-none  border-r text-gray-700 rounded-lg;\n  &:first-child {\n    @apply rounded-l-lg;\n  }\n\n  &:last-child {\n    @apply rounded-r-lg border-r;\n  }\n  &:hover {\n    @apply bg-grayModern-100;\n    color: #364152 !important;\n    background-color: #eef2f6 !important;\n    border-color: #cdd5df !important;\n  }\n}\n.link-button {\n  @apply border-none rounded-none min-w-fit border-r p-0 rounded-lg;\n  box-shadow: 0 2px 0 rgba(0, 0, 0, 0) !important;\n  color: #2562a9 !important;\n  &:hover {\n    @apply bg-grayModern-100;\n    color: #3b72b2 !important;\n    background-color: #ffffff !important;\n    border-color: #ffffff !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
