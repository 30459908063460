import { apiPrimeSlice } from 'app-redux/store';
import {
  BillingSearchModel,
  GetAuditLogModelResponse,
  GetBillingModel,
  GetEntityModel,
  GetEntityRoleModel,
  GetEntityWithPersonsModel,
  UboGetReportResponse,
  UpdateEntityRegisteredTypeModel,
} from 'solstice-types';

export interface CompanyData extends GetEntityModel {
  entityType: string;
}

interface GetBillingTransactionsModel {
  dateMonth: string;
  totalValue: number;
}

const groupsApiSlice = apiPrimeSlice.injectEndpoints({
  endpoints: (builder) => ({
    primeSearch: builder.mutation<GetEntityModel[], any>({
      query: ({ searchTerm, pageNumber, pageSize, status, entityType }) => ({
        url: `/entities/prime-search`,
        method: 'POST',
        body: { searchTerm, pageNumber, pageSize, status },
        params: entityType ? { entityType } : {},
      }),
    }),

    searchProvider: builder.mutation<CompanyData[], { searchTerm: string }>({
      query: (searchTerm) => ({
        url: `/entities/provider-search`,
        method: 'POST',
        body: searchTerm,
      }),
    }),

    searchAsic: builder.mutation<UboGetReportResponse, { searchTerm: string }>({
      query: (searchTerm) => ({
        url: `/entities/asic-search`,
        method: 'POST',
        body: searchTerm,
      }),
    }),

    getAllGroups: builder.query<GetEntityModel[], void>({
      query: () => ({
        url: '/entities',
        method: 'GET',
      }),
      providesTags: ['Groups'],
    }),

    getEntityById: builder.query<GetEntityWithPersonsModel, { id: string }>({
      query: ({ id }) => ({
        url: `/entities/${id}`,
        method: 'GET',
      }),
      providesTags: ['GetEntityById'],
    }),

    getEntityRoles: builder.query<GetEntityRoleModel[], any>({
      query: ({ type } = {}) => ({
        url: `/entity-roles`,
        method: 'GET',
        params: type ? { type } : {},
      }),
      providesTags: ['EntityRoles'],
    }),

    getAuditLogById: builder.query<GetAuditLogModelResponse, string>({
      query: (id) => ({
        url: `/audit-logs/${id}`,
        method: 'GET',
      }),
    }),

    getBillingData: builder.mutation<GetBillingModel[], BillingSearchModel>({
      query: ({ entityTypeId, startFrom, client, pageSize, pageNumber }) => ({
        url: '/billing',
        method: 'POST',
        body: {
          entityTypeId,
          startFrom,
          client,
          pageSize,
          pageNumber,
        },
      }),
    }),

    getBillingTransactions: builder.query<GetBillingTransactionsModel[], void>({
      query: () => ({
        url: '/billing/chart',
        method: 'GET',
      }),
    }),

    updateTrustType: builder.mutation<
      UpdateEntityRegisteredTypeModel,
      { registeredTypeId: string; entityId: string }
    >({
      query: ({ registeredTypeId, entityId }) => ({
        url: `/entities/${entityId}`,
        method: 'PUT',
        body: { registeredTypeId },
      }),
    }),
  }),
});

export const {
  usePrimeSearchMutation,
  useGetAllGroupsQuery,
  useSearchProviderMutation,
  useSearchAsicMutation,
  useGetEntityByIdQuery,
  useLazyGetEntityByIdQuery,
  useGetEntityRolesQuery,
  useGetAuditLogByIdQuery,
  useUpdateTrustTypeMutation,
  useGetBillingDataMutation,
  useGetBillingTransactionsQuery,
} = groupsApiSlice;
