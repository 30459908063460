import { useCallback, useEffect, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import { TableFilters, TableSearch } from 'components';
import { useWindowSize } from 'hooks/useWindowSize';

interface TableFiltersProps {
  activeFilter: string;
  handleFilterClick: (filter: string) => void;
  data: any;
  searchValue?: string;
  onChange?: (value: string) => void;
  setSearchValue?: (value: string) => void;
}

export const TableHeader = ({
  activeFilter,
  handleFilterClick,
  data,
  searchValue,
  onChange,
  setSearchValue,
}: TableFiltersProps) => {
  const [showDisplayArrow, setDisplayArrow] = useState({
    left: false,
    right: false,
  });
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const filtersContainerRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  const scrollLeft = () => {
    if (filtersContainerRef.current) {
      filtersContainerRef.current.scrollBy({
        left: -150,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (filtersContainerRef.current) {
      filtersContainerRef.current.scrollBy({
        left: 150,
        behavior: 'smooth',
      });
    }
  };

  const checkOverflow = useCallback(() => {
    const container = filtersContainerRef.current;
    if (container) {
      setDisplayArrow({
        left: container.scrollLeft > 0,
        right: container.scrollLeft + container.clientWidth < container.scrollWidth,
      });
    }
  }, []);

  useEffect(() => {
    checkOverflow();

    const container = filtersContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkOverflow);
    }

    window.addEventListener('resize', checkOverflow);

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkOverflow);
      }
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow]);

  useEffect(() => {
    checkOverflow();
  }, [width, checkOverflow, isSearchOpen]);

  return (
    <div className="-translate-x-7 flex justify-between items-center py-[11px] w-full">
      <div className="overflow-hidden flex whitespace-nowrap relative transition-all duration-300 w-[calc(100vw-380px)]">
        <Button
          icon={<LeftOutlined />}
          onClick={scrollLeft}
          type="link"
          hidden={!showDisplayArrow.left}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 max-w-5"
        />

        <div ref={filtersContainerRef} className="overflow-hidden mx-8">
          <TableFilters
            handleFilterClick={handleFilterClick}
            activeFilter={activeFilter}
            data={data}
          />
        </div>

        <Button
          icon={<RightOutlined />}
          onClick={scrollRight}
          type="link"
          hidden={!showDisplayArrow.right}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 max-w-4 h-10"
        />
      </div>

      <TableSearch
        onChange={onChange}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        className="translate-x-8"
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
      />
    </div>
  );
};
