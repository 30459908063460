import { Rule } from 'antd/es/form';
import { SelectOption } from 'components';
import { relationshipOptions } from 'utils/EntityTypes';
import { CompanyRoles, TrustRoles } from 'utils/enums';

interface InputProps {
  placeholder?: string;
  className?: string;
  maxLength?: number;
}

export interface NewIndividualFields {
  type: string;
  label: string;
  name: string;
  rules: Rule[];
  props: InputProps;
}

export const creatorId = '349776df-a736-40c3-b8ce-682c228183f5';

export const lettersOnlyRule = {
  pattern: /^[a-zA-Z]+$/,
  message: 'Please enter only letters',
};

export const newIndividualFields: NewIndividualFields[] = [
  {
    type: 'input',
    label: 'First Name',
    name: 'firstName',
    rules: [{ required: true, message: 'This field is required' }, lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Middle Name',
    name: 'middleName',
    rules: [lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Last Name',
    name: 'lastName',
    rules: [{ required: true, message: 'This field is required' }, lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'datePicker',
    label: 'Date of Birth',
    name: 'dateOfBirth',
    rules: [{ required: true, message: 'This field is required' }],
    props: { className: 'min-w-[194px] h-10', placeholder: '' },
  },
  {
    type: 'input',
    label: 'Email',
    name: 'email',
    rules: [
      { required: true, message: 'This field is required' },
      {
        type: 'email',
        message: 'Please enter a valid email address',
      },
    ],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'phoneNumber',
    label: 'Mobile Phone',
    name: 'mobilePhone',
    rules: [
      { required: true, message: 'This field is required' },
      {
        pattern: /^(04|05)\d{2}\s?\d{3}\s?\d{3}$/,
        message: '',
      },
      {
        validator: (_: any, value: string) => {
          if (!value) return Promise.resolve();
          const digits = value.replace(/\D/g, '');
          if (digits.length === 10 && (digits.startsWith('04') || digits.startsWith('05'))) {
            return Promise.resolve();
          }
          return Promise.reject('04XX XXX XXX or 05XX XXX XXX');
        },
      },
    ],
    props: { className: 'min-w-[194px]', maxLength: 10 },
  },
];

export const relationshipIndividualOptions = relationshipOptions?.map((role) => ({
  value: role.id ?? '',
  label: role.label ?? '',
}));

export const roleOptions: SelectOption[] = Object.values(CompanyRoles).map((value) => ({
  value,
  label: value,
}));

export const trustOptions: SelectOption[] = Object.values(TrustRoles).map((value) => ({
  value,
  label: value,
}));

export const newAssociatedPartyFields = [
  {
    type: 'input',
    label: 'First Name',
    name: 'firstName',
    rules: [{ required: true, message: 'This field is required' }, lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Middle Name',
    name: 'middleName',
    rules: [lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Last Name',
    name: 'lastName',
    rules: [{ required: true, message: 'This field is required' }, lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'datePicker',
    label: 'Date of Birth',
    name: 'dateOfBirth',
    rules: [{ required: true, message: 'This field is required' }],
    props: { className: 'min-w-[302px] h-10', placeholder: '' },
  },
  {
    type: 'input',
    label: 'Email',
    name: 'email',
    rules: [
      { required: true, message: 'This field is required' },
      {
        type: 'email',
        message: 'Please enter a valid email address',
      },
    ],
    props: { className: 'min-w-[302px]' },
  },

  {
    type: 'input',
    label: 'Mobile Phone',
    name: 'mobilePhone',
    rules: [
      { required: true, message: 'This field is required' },
      {
        pattern: /^0[45][0-9]{8}$/,
        message: '04XX XXX XXX or 05XX XXX XXX',
      },
    ],
    props: { className: 'min-w-[302px]', maxLength: 10 },
  },

  {
    type: 'select',
    label: 'Relationship',
    name: 'relationship',
    rules: [{ required: true, message: 'This field is required' }],
    props: {
      className: 'min-w-[302px] h-10',
    },
  },
];

export const newCompanyIndividualFields = [
  {
    type: 'input',
    label: 'First Name',
    name: 'firstName',
    rules: [{ required: true, message: 'This field is required' }, lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Middle Name',
    name: 'middleName',
    rules: [lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Last Name',
    name: 'lastName',
    rules: [{ required: true, message: 'This field is required' }, lettersOnlyRule],
    props: { className: 'min-w-[194px]' },
  },
  {
    type: 'input',
    label: 'Email',
    name: 'email',
    rules: [
      { required: true, message: 'This field is required' },
      {
        type: 'email',
        message: 'Please enter a valid email address',
      },
    ],
    props: { className: 'min-w-[302px]' },
  },

  {
    type: 'input',
    label: 'Mobile Phone',
    name: 'phone',
    rules: [
      { required: true, message: 'This field is required' },
      {
        pattern: /^0[45]\d{8}$/,
        message: '04XX XXX XXX or 05XX XXX XXX',
      },
    ],
    props: { className: 'min-w-[302px]', maxLength: 10 },
  },
  {
    type: 'input',
    label: 'Ownership',
    name: 'ownership',
    rules: [
      { required: true, message: 'This field is required' },
      {
        pattern: /^[0-9]+$/,
        message: 'Only numbers are allowed',
      },
    ],
    props: { className: 'min-w-[302px]', prefix: '%' },
  },

  {
    type: 'select',
    label: 'Role',
    name: 'role',
    rules: [{ required: true, message: 'This field is required' }],
    props: { className: 'h-10 min-w-[302px]' },
  },
];
