import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { MutationActionCreatorResult, MutationDefinition } from '@reduxjs/toolkit/query';
import PlusSquare from '@untitled-ui/icons-react/build/esm/PlusSquare';

import { Spin } from 'antd';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { setAbnSearchIdentifier, setExistingCompany } from 'api/client-side-slices/GroupsSlice';
import { useAppDispatch, useAppSelector } from 'app-redux/hooks';
import { BaseButton, SearchInput } from 'components';
import { GetEntityModel, GetPersonModel } from 'solstice-types';
import { useDebounce } from 'use-debounce';
import { UserModel } from 'utils';
import { SelectedCompany } from '../components/SelectedCompany';

interface EntitySearchStepProps {
  setStep: (step: number) => void;
  isLoading: boolean;
  type: 'Company' | 'Trust';
  searchGroup: (
    params: any
  ) => MutationActionCreatorResult<MutationDefinition<any, any, any, GetPersonModel[], 'primeApi'>>;
  primeData?: GetEntityModel[];
}

export const EntitySearchStep = ({
  setStep,
  searchGroup,
  isLoading,
  type,
  primeData: filteredData,
}: EntitySearchStepProps) => {
  const { existingCompany } = useAppSelector((state) => state.groups);
  const entityLabel = type === 'Company' ? 'company' : 'trust/other';
  const addNewEntityLabel = type === 'Company' ? 'Add New Company' : 'Add New Trust/Other';

  const [isExistingVisible, setIsExistingVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedQuery] = useDebounce(inputValue, 400);
  const [hasError, setHasError] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { control, reset } = useFormContext();

  const searchOptions =
    hasError || (inputValue && filteredData?.length === 0)
      ? [
          {
            value: 'no-result',
            label: <div className="text-gray-500 font-medium">No results found.</div>,
          },
        ]
      : filteredData?.map((user: UserModel) => ({
          value: user.id,
          label: <SelectedCompany user={user} searchQuery={inputValue} areOptionsVisible />,
        }));

  const handleSelect = (value: string) => {
    if (value === 'no-result' || value === 'loading') return;

    const selectedItem = filteredData?.find((item) => item.id === value);
    if (selectedItem) {
      const { acn, abn } = selectedItem;

      setIsExistingVisible(true);
      setInputValue('');

      dispatch(setExistingCompany(selectedItem));
      dispatch(
        setAbnSearchIdentifier({
          entityIdentifier: (acn ?? abn) || '',
          identifierType: acn ? 'acn' : 'abn',
          entityId: selectedItem.id ?? '',
        })
      );
      dispatch(setDrawerCloseDisabled(false));
    }
  };

  const handleSearch = (value: string) => {
    setInputValue(value);
    dispatch(setExistingCompany(null));

    if (!value) {
      dispatch(setExistingCompany(null));
      setIsExistingVisible(false);
    }
  };

  const handleDisplayEntity = () => {
    dispatch(setDrawerCloseDisabled(true));
    dispatch(setExistingCompany(null));
    setInputValue('');
    reset();
    setIsExistingVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery) {
        setHasError(false);
        try {
          await searchGroup({ searchTerm: debouncedQuery, entityType: type }).unwrap();
        } catch (error: any) {
          if (error.status === 404) {
            setHasError(true);
          }
        }
      }
    };

    fetchData();
  }, [debouncedQuery, searchGroup, type]);

  return (
    <div className="flex flex-col justify-between overflow-auto h-[calc(100vh-130px)]">
      <div className="flex justify-start items-start flex-col ml-1.5 mr-1.5 gap-[20px]">
        <span className="font-bold text-2xl text-gray-800">Start New Verification</span>
        <span className="text-gray-600">
          You can search for an existing {entityLabel} or add a new one.
        </span>

        <Controller
          name="primeEntityName"
          control={control}
          rules={{ required: 'Name is required', minLength: { value: 3, message: '' } }}
          render={({ field }) => (
            <>
              <SearchInput
                {...field}
                inputClassName="h-10 w-full"
                onSearch={handleSearch}
                options={searchOptions}
                onSelect={handleSelect}
                value={inputValue}
                placeholder="Type to search..."
                autoFocus
                suffixIcon={
                  isLoading ? (
                    <Spin className="mt-2" size="default" indicator={<LoadingOutlined spin />} />
                  ) : (
                    ''
                  )
                }
              />
            </>
          )}
        />

        {existingCompany && (
          <SelectedCompany
            user={existingCompany}
            searchQuery={inputValue}
            isExistingVisible={isExistingVisible}
            handleDisplayCompany={handleDisplayEntity}
          />
        )}

        <BaseButton className="link-button" onClick={() => setStep(1)}>
          <PlusSquare width={15} height={15} />
          {addNewEntityLabel}
        </BaseButton>
      </div>
    </div>
  );
};
