import React, { forwardRef } from 'react';

import { Input as AntInput, InputProps, InputRef } from 'antd';
import { twMerge } from 'tailwind-merge';

export const Input = forwardRef<InputRef, InputProps>(
  ({ className = '', ...props }: InputProps, ref) => {
    return <AntInput ref={ref} className={twMerge('text-gray-900', className)} {...props} />;
  }
);

Input.displayName = 'Input';
