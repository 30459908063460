import { ReactNode, useMemo, useState } from 'react';
import BuildingIcon from '@untitled-ui/icons-react/build/esm/Building03';
import UserPlusIcon01 from '@untitled-ui/icons-react/build/esm/UserPlus01';
import UserPlusIcon from '@untitled-ui/icons-react/build/esm/UsersPlus';

import { useDrawer } from 'components/Drawer/Drawer';
import { ClientType, DropdownItem } from 'utils';
import { DropdownMenu } from '../../components/DropdownMenu';
import { Tab, TabType } from '../../components/Tab/Tab';
import { CompanyVerificationFlow } from './AddNewCompany/AddNewCompanyFlow';
import { IndividualContent } from './AddNewIndividual/components';
import { GroupsTab } from './Tabs/GroupsTab';
import { IndividualsTab } from './Tabs/IndividualsTab';
import { AddNewTrustFlow } from './AddNewTrust';

export const DROPDOWN_ITEMS: DropdownItem[] = [
  {
    type: 'individual',
    label: 'Individual',
    icon: <UserPlusIcon01 className="text-gray-500 h-5 w-5" />,
  },
  {
    type: 'company',
    label: 'Company',
    icon: <BuildingIcon className="text-gray-500 h-5  w-5" />,
  },
  {
    type: 'trust',
    label: 'Trust/Other',
    icon: <UserPlusIcon className="text-gray-500 h-5 w-5" />,
  },
];

export const KnowYourCustomer = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { openDrawer } = useDrawer();

  const tabs: TabType[] = useMemo(
    () => [
      {
        tabButton: 'Groups',
        tabContent: <GroupsTab />,
        testId: 'groups',
      },
      {
        tabButton: 'Individuals',
        tabContent: <IndividualsTab />,
        testId: 'individuals',
      },
    ],
    []
  );

  const handleAddNewClient = (clientType: ClientType) => {
    let drawerContent: ReactNode;

    switch (clientType) {
      case 'individual':
        openDrawer(<IndividualContent />, '');
        break;
      case 'company':
        openDrawer(<CompanyVerificationFlow />, '');
        break;
      case 'trust':
        openDrawer(<AddNewTrustFlow />, '');
        break;
      default:
        drawerContent = <div>Unknown Client Type</div>;
        return drawerContent;
    }
  };

  return (
    <section className="my-8 text-sm px-5">
      <header className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-grayModern-800">Know Your Customer</h1>
        <DropdownMenu
          buttonLabel="Start New Verification"
          menuItems={DROPDOWN_ITEMS.map((item: DropdownItem) => (
            <button
              key={item.type}
              className="group flex w-full items-center gap-2 rounded-lg py-1 hover:bg-grayModern-100 focus:bg-grayModern-100"
              onClick={() => handleAddNewClient(item.type)}
            >
              {item.icon}
              <span>{item.label}</span>
            </button>
          ))}
        />
      </header>

      <p className="text-grayModern-600 font-medium mt-2">Manage your clients KYC reports</p>

      <div className="mt-5">
        <Tab tabs={tabs} activeTabIndex={activeTab} setSelectedIndex={setActiveTab} />
      </div>
    </section>
  );
};
