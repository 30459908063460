/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { notification } from 'antd';
import { setCurentStep } from 'api/client-side-slices/DesignSlice';
import { useLazyGetEntityByIdQuery, usePrimeSearchMutation } from 'api/EntitiesApiSlice';
import { useAddIndividualMutation } from 'api/IndividualsApiSlice';
import { useAddNewTrustMutation, useUploadDocumentsMutation } from 'api/TrustApiSlice';
import { useAppDispatch, useAppSelector } from 'app-redux/hooks';
import { ModalComponent, useModal } from 'components';
import { useDrawer } from 'components/Drawer';
import { CreatePersonsWithEntityModel } from 'solstice-types';
import { removeWhiteSpaces } from 'utils';
import { EntitySearchStep } from '../AddNewCompany/Steps/EntitySearchStep';
import { IndividualDrawerFooter } from '../AddNewIndividual/IndividualDrawerFooter';
import { AddTrustIndividualsStep } from './Steps/AddTrustIndividualsStep';
import { AddTrustStep } from './Steps/AddTrustStep';

enum Step {
  SEARCH_TRUST,
  ADD_TRUST,
  ADD_INDIVIDUALS,
}

export const AddNewTrustFlow = () => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.SEARCH_TRUST);
  const [stepHistory, setStepHistory] = useState<Step[]>([Step.SEARCH_TRUST]);
  const [error, setError] = useState<string | null>(null);
  const [forceStorePerson, setForceStorePerson] = useState<boolean>(false);

  const [addIndividuals, { isLoading: areIndividualsLoading }] = useAddIndividualMutation();
  const [addNewTrust, { isLoading: isAddNewTrustLoading }] = useAddNewTrustMutation();

  const [searchPrime, { data: primeData, isLoading: isGroupSearchLoading }] =
    usePrimeSearchMutation();

  const [fetchEntity, { isLoading: isEntityLoading }] = useLazyGetEntityByIdQuery();
  const [uploadTrustDocuments, { isLoading: areDocumentsLoading }] = useUploadDocumentsMutation();

  const { existingCompany, uboReportData } = useAppSelector((state) => state.groups);
  const { closeDrawer } = useDrawer();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    watch,
    getValues,
  } = methods;

  const { formTrustType, formTrustName } = getValues();

  const renderButtonText = () => {
    if (existingCompany) {
      return 'Start Verification';
    }
    return currentStep === Step.ADD_TRUST ? 'Continue' : 'Start Verification';
  };

  const goToNextStep = (nextStep: Step) => {
    setCurrentStep(nextStep);
    setStepHistory((prevHistory) => [...prevHistory, nextStep]);
  };

  const goToPreviousStep = () => {
    dispatch(setCurentStep(0));

    setStepHistory((prevHistory) => {
      if (prevHistory.length > 1) {
        const updatedHistory = prevHistory.slice(0, -1);
        setCurrentStep(updatedHistory[updatedHistory.length - 1]);
        return updatedHistory;
      }
      return prevHistory;
    });
  };

  const formUploadedFiles = watch('uploaded_files');

  const addTrustIndividuals = async (data: any) => {
    try {
      await addIndividuals(data).unwrap();
      closeDrawer();
      navigate(`/entity-details/${data.newIndividual[0].entityId}`);

      notification.success({
        message: 'Trust added successfully!',
        className: 'custom-success-message',
        placement: 'top',
        icon: <></>,
      });
    } catch (error: any) {
      if (error?.originalStatus === 400) {
        openModal(
          <ModalComponent
            bodyContent={modalBodyText}
            isLoading={areIndividualsLoading}
            onCancel={closeModal}
            onUpdate={() => {
              setForceStorePerson(!forceStorePerson);
              setTimeout(() => {
                onSubmit(getValues());
              }, 2000);
            }}
          />,
          'Duplicated Entity'
        );
      } else {
        notification.error({
          message: `Oops! Something went wrong. Please try again.`,
          className: 'custom-error-message',
          placement: 'top',
          icon: <></>,
        });
      }
    }
  };

  const { openModal, closeModal } = useModal();

  const modalBodyText =
    'This individual already exists in Prime. You can either update the data for the existing individual or use the Search option to start the verification process.';

  const onSubmit = async (data: any) => {
    if (currentStep === Step.SEARCH_TRUST && existingCompany) {
      dispatch(setCurentStep(0));

      const response = await fetchEntity({ id: existingCompany.id ?? '' }).unwrap();

      if (!response || !response.persons) {
        return;
      }

      const personsData = response.persons.map((person: any) => ({
        email: person.email || '',
        firstName: person.firstName || '',
        lastName: person.lastName || '',
        middleName: person.middleName || '',
        mobilePhone: person.mobilePhone || '',
        entityId: response.id || '',
        roleId: '00dcd0f3-073f-48d4-9d49-4b35e4eab9cc',
        ownershipPercentage: person.ownershipPercentage || 0,
        beneficiallyHeld: person.beneficiallyHeld ?? false,
        forceStore: true, //TODO change to different logic
      }));

      addTrustIndividuals({ newIndividual: personsData, isGroup: false });
    } else if (currentStep === Step.ADD_TRUST) {
      goToNextStep(Step.ADD_INDIVIDUALS);
      dispatch(setCurentStep(1));
    } else {
      const formValues = getValues();
      dispatch(setCurentStep(2));

      const formattedData = Object.keys(formValues)
        .filter((key) => key.startsWith('firstName_'))
        .map((key, index) => {
          const i = key.split('_')[1];
          return {
            firstName: formValues[`firstName_${i}`] || '',
            lastName: formValues[`lastName_${i}`] || '',
            middleName: formValues[`middleName_${i}`] || '',
            email: formValues[`email_${i}`] || '',
            mobilePhone: removeWhiteSpaces(formValues[`mobilePhone_${i}`] || ''),
            ownershipPercentage: Number(formValues[`ownership_${i}`]) || 0,
            roleId: '161b9879-b255-4e4e-8fdf-0003dbfda35a', // Trust id
            forceStore: true,
          };
        })
        .filter((person) => person.firstName?.trim());

      const formData: CreatePersonsWithEntityModel = {
        persons: formattedData,
        entityTypeId: '41367f33-d015-45e0-84f9-cb379faaaa74',
        entityRegisteredTypeId: formTrustType,
        registeredName: formTrustName,
      };

      try {
        await addNewTrust({ newTrust: formData })
          .unwrap()
          .then((response: any) => {
            uploadTrustDocuments({
              entityId: response.entity.id,
              files: formUploadedFiles,
            })
              .unwrap()
              .then(() => {
                navigate(`/entity-details/${response.entity.id}`);
                closeDrawer();
                notification.success({
                  message: 'Trust added and files uploaded successfully!',
                  className: 'custom-success-message',
                  placement: 'top',
                  icon: <></>,
                });
              });
          });
      } catch (error) {
        notification.error({
          message: 'Oops! Something went wrong. Please try again.',
          className: 'custom-error-message',
          placement: 'top',
          icon: <></>,
        });
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case Step.SEARCH_TRUST:
        return (
          <EntitySearchStep
            setStep={goToNextStep}
            isLoading={isGroupSearchLoading}
            type="Trust"
            searchGroup={searchPrime}
            primeData={primeData}
          />
        );
      case Step.ADD_TRUST:
        return <AddTrustStep setStep={setCurrentStep} error={error ?? ''} />;
      case Step.ADD_INDIVIDUALS:
        return (
          <AddTrustIndividualsStep setStep={setCurrentStep} uploadedFiles={formUploadedFiles} />
        );
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods} handleSubmit={() => handleSubmit(onSubmit)}>
      {renderStep()}
      <IndividualDrawerFooter
        confirmButtonText={renderButtonText}
        isLoading={
          isEntityLoading || areIndividualsLoading || isAddNewTrustLoading || areDocumentsLoading
        }
        handlePreviousStep={goToPreviousStep}
        hasSteps={currentStep === Step.ADD_INDIVIDUALS}
        handleSave={handleSubmit(onSubmit)}
        isStartDisabled={formUploadedFiles?.length === 0}
      />
    </FormProvider>
  );
};
