import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyData } from 'api/EntitiesApiSlice';
import { GroupTableData } from 'pages';
import { GetEntityModel, UboGetReportResponse } from 'solstice-types';

interface AbnIdentifier {
  entityIdentifier: string;
  identifierType: string;
  entityId?: string;
}

interface GenericState {
  groupsData: GroupTableData | null;
  existingCompany: GetEntityModel | null;
  providerData: CompanyData[];
  error: string | null;
  abnIdentifier: AbnIdentifier;
  uboReportData: UboGetReportResponse | null;
}

const initialGenericState: GenericState = {
  groupsData: null,
  existingCompany: null,
  error: null,
  providerData: [],
  abnIdentifier: { entityIdentifier: '', identifierType: '', entityId: '' },
  uboReportData: null,
};

const groupsSlice = createSlice({
  name: 'groupsSlice',
  initialState: initialGenericState,
  reducers: {
    setGroupsData: (state, action: PayloadAction<GroupTableData>) => {
      state.groupsData = action.payload;
    },

    setExistingCompany: (state, action: PayloadAction<GetEntityModel | null>) => {
      state.existingCompany = action.payload;
    },
    setProviderDataSearch: (state, action: PayloadAction<CompanyData[]>) => {
      state.providerData = action.payload;
    },
    setAbnSearchIdentifier: (state, action: PayloadAction<AbnIdentifier>) => {
      state.abnIdentifier = action.payload;
    },
    setUboReportData: (state, action: PayloadAction<UboGetReportResponse>) => {
      state.uboReportData = action.payload;
    },

    resetState: () => initialGenericState,
  },
});

export const {
  setGroupsData,
  resetState,
  setExistingCompany,
  setProviderDataSearch,
  setAbnSearchIdentifier,
  setUboReportData,
} = groupsSlice.actions;

export default groupsSlice.reducer;
