// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-col.ant-form-item-label {
  font-weight: 600;
  padding: 0 0 4px !important;
}

label.ant-form-item-required::before {
  display: none !important;
}

.ant-form-item-explain-error {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
  font-size: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/BaseForm.scss"],"names":[],"mappings":"AACE;EAAA,gBAAA;EACA;AADA;;AAIF;EACE,wBAAA;AACF;;AAGE;EAAA,oBAAA;EAAA,gDAAA;EACA;AADA","sourcesContent":[".ant-col.ant-form-item-label {\n  @apply font-semibold;\n  padding: 0 0 4px !important;\n}\n\nlabel.ant-form-item-required::before {\n  display: none !important;\n}\n\n.ant-form-item-explain-error {\n  @apply text-red-100;\n  font-size: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
