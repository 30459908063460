interface IconProps {
  src: string;
  alt?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
}

export const Icon = ({
  src,
  alt = '',
  className = '',
  width = '1em',
  height = '1em',
  onClick,
}: IconProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={`inline-block ${className}`}
      style={{ width, height }}
      onClick={onClick}
    />
  );
};
