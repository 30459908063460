import { ReactNode } from 'react';

import { Navigation } from './Navigation';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <main className="flex h-screen overflow-hidden">
      <Navigation />
      <div className="flex-1 flex flex-col">
        <section>{children}</section>
      </div>
    </main>
  );
};
