import { apiPrimeSlice } from 'app-redux/store';
import {
  CreatePersonModel,
  CreatePersonsWithEntityModel,
  GetEntityRegisteredTypeModel,
} from 'solstice-types';

const trustApiSlice = apiPrimeSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadDocuments: builder.mutation<any, { entityId: string; files: File[] }>({
      query: ({ entityId, files }) => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));

        return {
          url: `/documents/${entityId}`,
          method: 'POST',
          body: formData,
        };
      },
    }),

    addNewTrust: builder.mutation<
      CreatePersonModel,
      {
        newTrust: CreatePersonsWithEntityModel;
      }
    >({
      query: ({ newTrust }) => ({
        url: '/persons/entity',
        method: 'POST',
        body: newTrust,
      }),
    }),

    getDocumentById: builder.query<{ url: string } | Blob, { documentId: string }>({
      query: ({ documentId }: { documentId: string }) => ({
        url: `/documents/${documentId}`,
        method: 'GET',
        responseHandler: async (response: Response) => {
          const contentType = response.headers.get('content-type');
          if (contentType?.includes('application/json')) {
            return response.json();
          }
          return response.blob();
        },
      }),
    }),

    getTrustTypes: builder.query<GetEntityRegisteredTypeModel[], void>({
      query: () => '/entity-registered-types',
    }),
  }),
});

export const {
  useUploadDocumentsMutation,
  useAddNewTrustMutation,
  useLazyGetDocumentByIdQuery,
  useGetTrustTypesQuery,
} = trustApiSlice;
