import React, { forwardRef, Ref } from 'react';

import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

export interface SelectOption {
  value: string | number;
  label: React.ReactNode;
  disabled?: boolean;
}

export interface SelectProps extends Omit<AntSelectProps, 'options'> {
  options: SelectOption[];
  label?: string;
  error?: string;
  size?: SizeType;
  fullWidth?: boolean;
  loading?: boolean;
  helperText?: string;
  isUserGuest?: boolean;
}

export const BaseSelect = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      options,
      label,
      error,
      size = 'middle',
      fullWidth = false,
      loading = false,
      helperText,
      isUserGuest,
      ...rest
    }: SelectProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div className={`select-wrapper ${fullWidth ? 'w-full' : ''}`} ref={ref} hidden={isUserGuest}>
        {label && <label className="block mb-1 text-sm font-medium text-gray-700">{label}</label>}
        <AntSelect
          className={`${fullWidth ? 'w-full' : ''} ${error ? 'border-red-500' : ''}`}
          size={size}
          loading={loading}
          {...rest}
        >
          {options.map((option) => (
            <AntSelect.Option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </AntSelect.Option>
          ))}
        </AntSelect>
        {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
        {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
      </div>
    );
  }
);
