// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ant-card .ant-card-body {
  padding: 1.25rem !important;
}

.company-individual-table .ant-table-cell {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.label-class {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.no-expand-icon .ant-table-row-expand-icon-cell {
  display: none;
}

.no-expand-icon .ant-table-thead > tr > th:first-child,
.no-expand-icon .ant-table-tbody > tr > td:first-child {
  padding: 12px;
  border-top: none;
}

th.ant-table-cell {
  padding-left: 1rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-table-wrapper tr.ant-table-expanded-row > td {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}`, "",{"version":3,"sources":["webpack://./src/pages/KnowYourCustomer/AddNewCompany/Steps/AddCompanySteps.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,mBAAA;EAAA;AAAA;;AAIA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,sBAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;;AAGF;EACE,aAAA;AACF;;AAEA;;EAEE,aAAA;EACA,gBAAA;AACF;;AAGE;EAAA,kBAAA;EACA,yBAAA;EACA;AAFA;;AAMA;EAAA,kBAAA;EAAA;AAAA","sourcesContent":[".company-info {\n  @apply flex items-center gap-4;\n}\n\n.ant-card .ant-card-body {\n  @apply p-5 !important;\n}\n\n.company-individual-table .ant-table-cell {\n  @apply text-gray-900 font-medium text-xs;\n}\n\n.label-class {\n  @apply text-xs font-semibold text-gray-600 mb-1;\n}\n\n.no-expand-icon .ant-table-row-expand-icon-cell {\n  display: none;\n}\n\n.no-expand-icon .ant-table-thead > tr > th:first-child,\n.no-expand-icon .ant-table-tbody > tr > td:first-child {\n  padding: 12px;\n  border-top: none;\n}\n\nth.ant-table-cell {\n  @apply pl-4;\n  padding-top: 0 !important;\n  padding-bottom: 0 !important;\n}\n\n.ant-table-wrapper tr.ant-table-expanded-row > td {\n  @apply bg-white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
