// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-success-message {
  border-radius: 0.5rem;
  font-weight: 500;
  background-color: #e3f6eb !important;
  color: #005838 !important;
  max-width: 344px !important;
  padding: 12px 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
}

.custom-success-message.ant-notification-notice-wrapper {
  background-color: #e3f6eb !important;
}

.custom-success-message .anticon {
  display: none !important;
}

.custom-success-message .ant-notification-notice-message {
  margin: 0 !important;
  padding: 0 !important;
  color: #005838 !important;
}

.custom-success-message .ant-notification-notice-close {
  margin: 0 !important;
  padding: 0 !important;
  top: 13px !important;
  color: black !important;
  display: block !important;
  color: #005838 !important;
}

.ant-modal-content {
  max-width: 400px;
}

.ant-notification-notice-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/KnowYourCustomer/AddNewIndividual/components/IndividualFooter.scss"],"names":[],"mappings":"AACE;EAAA,qBAAA;EAAA,gBAAA;EACA,oCAAA;EACA,yBAAA;EACA,2BAAA;EACA,6BAAA;EACA,wBAAA;EACA,8BAAA;EACA,kCAAA;EACA;AARA;;AAWF;EACE,oCAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,oBAAA;EACA,qBAAA;EACA,yBAAA;AACF;;AAEA;EACE,oBAAA;EACA,qBAAA;EACA,oBAAA;EACA,uBAAA;EACA,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAGE;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,YAAA;EACA;AADA","sourcesContent":[".custom-success-message {\n  @apply rounded-lg  font-medium;\n  background-color: #e3f6eb !important;\n  color: #005838 !important;\n  max-width: 344px !important;\n  padding: 12px 16px !important;\n  display: flex !important;\n  align-items: center !important;\n  justify-content: center !important;\n  font-size: 14px !important;\n}\n\n.custom-success-message.ant-notification-notice-wrapper {\n  background-color: #e3f6eb !important;\n}\n\n.custom-success-message .anticon {\n  display: none !important;\n}\n\n.custom-success-message .ant-notification-notice-message {\n  margin: 0 !important;\n  padding: 0 !important;\n  color: #005838 !important;\n}\n\n.custom-success-message .ant-notification-notice-close {\n  margin: 0 !important;\n  padding: 0 !important;\n  top: 13px !important;\n  color: black !important;\n  display: block !important;\n  color: #005838 !important;\n}\n\n.ant-modal-content {\n  max-width: 400px;\n}\n\n.ant-notification-notice-content {\n  @apply flex items-center justify-center p-0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
