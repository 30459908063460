import { UserModel } from './interfaces';

export const getUserInitials = (data: UserModel): string =>
  `${data?.firstName?.charAt(0) ?? ''}${data?.lastName?.charAt(0) ?? ''}`.toUpperCase();

export const getUserInitialsFromUsername = (username: string): JSX.Element => {
  const [firstName, lastName] = username.split(' ');

  return (
    <div className="rounded-full bg-gray-400 text-white w-6 h-6 flex items-center justify-center">
      {`${firstName?.charAt(0).toUpperCase() ?? ''} ${lastName?.charAt(0).toUpperCase() ?? ''}`}
    </div>
  );
};

export const highlightText = (text: string, query: string) => {
  if (!query) return text;

  const parts = (text || '').split(new RegExp(`(${query})`, 'gi'));

  return parts.map((part, index) => {
    if (typeof part === 'string' && part.toLowerCase() === query.toLowerCase()) {
      return (
        <span key={index} className="text-gray-900 font-medium">
          {part}
        </span>
      );
    }
    return part;
  });
};

export const getFullName = (
  firstName: string | null | undefined = '',
  lastName: string | null | undefined = ''
): string => {
  if (!firstName && !lastName) {
    return '-';
  }

  return `${firstName ?? ''} ${lastName ?? ''}`;
};

export const generateRandomEmail = () => {
  const randomString = Math.random().toString(36).substring(7);
  return `${randomString}@example.com`;
};

export const isErrorWithStatus = (
  error: unknown
): error is { originalStatus: number; message: string } => {
  return (
    typeof error === 'object' && error !== null && 'originalStatus' in error && 'message' in error
  );
};

export interface SelectOption {
  value: string;
  label: string;
}

export const getFullAddress = (
  street?: string | null,
  city?: string | null,
  state?: string | null,
  postcode?: string | null
): string => {
  return [street, city, state, postcode].filter(Boolean).join(', ');
};

export const formatPhoneNumber = (input: string | React.ChangeEvent<HTMLInputElement>): string => {
  if (!input) return '';

  const value = typeof input === 'string' ? input : input.target.value;

  const cleaned = value.replace(/\D/g, '');
  const truncated = cleaned.slice(0, 10);

  const match = truncated.match(/^(\d{0,4})(\d{0,3})(\d{0,3})$/);
  if (!match) return truncated;

  const parts = [match[1], match[2], match[3]].filter(Boolean);
  return parts.join(' ');
};
export const formatDisplayPhoneNumber = (phoneNumber: string | undefined | null): string => {
  if (!phoneNumber) return '';

  const cleaned = phoneNumber.replace(/\D/g, '');

  const match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
  if (!match) return cleaned;

  return `${match[1]} ${match[2]} ${match[3]}`;
};

export const removeWhiteSpaces = (currentValue: string) => {
  const sanitizedValue = currentValue.replace(/\s+/g, '');
  return sanitizedValue;
};

export const getInitialsFromCookies = (fullName: string) => {
  const initials = fullName
    ?.split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase();

  return (
    <div className="flex items-center justify-center w-8 h-8 bg-blue-500 text-white font-semibold rounded-full">
      {initials}
    </div>
  );
};

export const isLocalhost = () => {
  const hostname = window.location.hostname;

  return (
    hostname.includes('localhost') ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    hostname.endsWith('.localhost')
  );
};
