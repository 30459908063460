// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker .ant-picker-outlined {
  width: 194px !important;
}

.ant-select-selection-item {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  font-weight: 500 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/KnowYourCustomer/AddNewTrust/components/AddTrustIndividualForm.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAGE;EAAA,oBAAA;EAAA,6CAAA;EACA;AADA","sourcesContent":[".ant-picker .ant-picker-outlined {\n  width: 194px !important;\n}\n\n.ant-select-selection-item {\n  @apply text-gray-900;\n  font-weight: 500 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
