import { useEffect, useState } from 'react';

interface WindowSizeModel {
  width: number;
  height: number;
}

export const useWindowSize = (): WindowSizeModel => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = (): void => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { width, height } = size;
  return { width, height };
};
