import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { setGroupsData } from 'api/client-side-slices/GroupsSlice';
import { useGetAllGroupsQuery, usePrimeSearchMutation } from 'api/EntitiesApiSlice';
import { useAppDispatch } from 'app-redux/hooks';
import { TableComponent } from 'components';
import { useDebounce } from 'use-debounce';
import { Status } from 'utils';
import { groupColumns } from 'utils/constants';
import { TableHeader } from './components/TableHeader';

export type GroupTableData = {
  registeredName: ReactNode;
  entityTypeName: ReactNode;
  abn: ReactNode;
  status?: Status;
  assignedTo: ReactNode;
  lastUpdated: ReactNode;
  location: string;
  acn: string;
  id: number;
};

export const GroupsTab = () => {
  const [searchValue, setSearchValue] = useState('');
  const [activeFilter, setActiveFilter] = useState<string>('');
  const [hasSearched, setHasSearched] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: groupsData } = useGetAllGroupsQuery();
  const [fetchFilters, { data: filtersData, isLoading }] = usePrimeSearchMutation();
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const fetchFilteredData = (searchTerm: string, status: string) => {
    setHasSearched(searchTerm !== '' || status !== '');
    fetchFilters({
      searchTerm,
      status,
      pageSize: 99999999,
      pageNumber: 1,
    });
  };

  const handleFilterClick = (filter: string) => {
    setActiveFilter(filter);
    fetchFilteredData(debouncedSearchValue, filter);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleRowClick = (record: GroupTableData) => {
    dispatch(setGroupsData(record));
    navigate(`/entity-details/${record.id}`);
  };

  const sortedGroupsData = filtersData?.slice().sort((a, b) => {
    return (
      new Date(b.statusUpdatedAt ?? '').getTime() - new Date(a.statusUpdatedAt ?? '').getTime()
    );
  });

  const getNoDataMessage = () => {
    if (!hasSearched) {
      return 'You have no KYC verifications started. Start your first KYC verification today!';
    }
    return 'No records found';
  };

  useEffect(() => {
    if (searchValue === '') {
      fetchFilteredData('', activeFilter);
    }
  }, [searchValue]);

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      fetchFilteredData(debouncedSearchValue, activeFilter);
    }
  }, [debouncedSearchValue, activeFilter]);
  return (
    <div className="mb-[80px]">
      <TableHeader
        activeFilter={activeFilter}
        data={groupsData}
        handleFilterClick={handleFilterClick}
        onChange={handleSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <TableComponent
        columns={groupColumns}
        noDataMessage={getNoDataMessage()}
        dataSource={sortedGroupsData}
        className="entities-table"
        isLoading={isLoading}
        onRow={(record: GroupTableData) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};
