import { BaseSelect, SelectOption, StatusTag } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { twMerge } from 'tailwind-merge';
import { highlightText, UserModel } from 'utils';

interface SelectedIndividualProps {
  user: UserModel;
  searchQuery: string;
  isExistingVisible?: boolean;
  areOptionsVisible?: boolean;
  handleDisplayCompany?: (event: unknown) => void;
  isStatusVisible?: boolean;
  isSelectVisible?: boolean;
  selectOptions?: SelectOption[];
}

export const SelectedCompany = ({
  user,
  searchQuery,
  isExistingVisible,
  areOptionsVisible,
  handleDisplayCompany,
  isStatusVisible = true,
  isSelectVisible = false,
  selectOptions = [],
}: SelectedIndividualProps) => {
  const hiddenStyle = !areOptionsVisible ? !isExistingVisible : false;
  const displayStyle = !areOptionsVisible ? 'rounded-md bg-gray-100 py-2.5 px-2' : '';

  const { entityPersonCount, registeredName, status } = user ?? {};

  return (
    <div
      className={twMerge(`flex justify-between items-center w-full text-gray-500 ${displayStyle}`)}
      hidden={hiddenStyle}
    >
      <div className="flex gap-2 items-center">
        <div className="flex flex-col">
          <span className="font-semibold text-sm">
            {highlightText(registeredName ?? '', searchQuery)}
          </span>

          {entityPersonCount !== 0 && (
            <div className="font-normal text-xs">{entityPersonCount} individuals</div>
          )}
        </div>
      </div>

      <div className="flex gap-2">
        {isSelectVisible && (
          <BaseSelect
            options={selectOptions}
            className="min-w-40"
            placeholder="Select relationship"
          />
        )}

        {isStatusVisible && (
          <div className="flex items-center">
            <StatusTag status={status ?? ''} />
          </div>
        )}

        <DeleteButton onClick={handleDisplayCompany} areOptionsVisible={areOptionsVisible} />
      </div>
    </div>
  );
};
