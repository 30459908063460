export enum RelationshipType {
  SpouseDeFacto = 'Spouse / De Facto',
  ParentChild = 'Parent Child',
  FirstCousin = '1st Cousin',
  Sibling = 'Sibling',
  ParentInLaw = 'Parent-in-Law',
  ChildInLaw = 'Child-in-Law',
  SiblingInLaw = 'Sibling-in-law',
  StepParent = 'Step-parent',
  StepChild = 'Step-child',
  StepSibling = 'Step-sibling',
  HalfSibling = 'Half-sibling',
  Grandparent = 'Grandparent',
  Grandchild = 'Grandchild',
  AuntUncle = 'Aunt/Uncle',
  NephewNiece = 'Nephew/Niece',
  OtherFamilyRelationship = 'Other - Family Relationship',
  OtherLegalRelationship = 'Other - Legal Relationship',
  OtherProfessionalRelationship = 'Other - Professional Relationship',
  OtherPoliticalRelationship = 'Other - Political Relationship',
}
export enum CompanyRoles {
  Shareholder = 'Shareholder',
  Liquidator = 'Liquidator',
  Director = 'Director',
  Custodian = 'Custodian',
  OwnerProprietor = 'Owner / Proprietor',
  OtherProfessional = 'Other - Professional',
  Investor = 'Investor',
  Administrator = 'Administrator',
  Principle = 'Principle',
  Chairperson = 'Chairperson',
  Beneficiary = 'Beneficiary',
  Appointor = 'Appointor',
  Other = 'Other',
  CxO = 'CxO',
  OtherPolitical = 'Other - Political',
  Secretary = 'Secretary',
  Contractor = 'Contractor',
  Guarantor = 'Guarantor',
  Landlord = 'Landlord',
  Trustee = 'Trustee',
  Receiver = 'Receiver',
  Partner = 'Partner',
  Auditor = 'Auditor',
  Agent = 'Agent',
  OtherLegal = 'Other - Legal',
  Tenant = 'Tenant',
}

export enum TrustRoles {
  UltimateHoldingCompany = 'Ultimate Holding Company',
  HoldingCompany = 'Holding Company',
  ParentCompany = 'Parent Company',
  SubsidiaryCompany = 'Subsidiary Company',
  JointVenture = 'Joint Venture',
  MinorityOwnerships = 'Minority Ownerships',
  EquityAlliance = 'Equity Alliance',
  Consortium = 'Consortium',
  CrossOwnership = 'Cross-Ownership',
  Merger = 'Merger',
  InvestorVC = 'Investor / VC',
  Partnership = 'Partnership',
  Association = 'Association',
  Cooperative = 'Cooperative',
  OtherBusinessType = 'Other Business Type',
}
