import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { Modal } from 'antd';

interface ModalContextType {
  openModal: (modalContent: ReactNode, title?: string) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalTitle, setModalTitle] = useState<string | undefined>(undefined);

  const openModal = useCallback((content: ReactNode, title?: string) => {
    setModalContent(content);
    setModalTitle(title);
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setModalContent(null);
    setModalTitle(undefined);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        open={modalVisible}
        title={modalTitle}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
        destroyOnClose
      >
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
