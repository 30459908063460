export const routes = {
  login: '/',
  homeRoute: '/*',
  home: {
    knowYourCustomer: '/know-your-customer',
    knowYourCustomerTabs: {
      groups: '/know-your-customer/groups',
      individual: '/know-your-customer/individual',
    },
    companyDetails: '/entity-details/:id',
    auditLog: '/entity-details/:id/audit-log',
    individualDetails: '/individual-details/:id',
    settings: {
      settingsRoute: '/settings/*',
    },
    profile: '/profile',
    billing: '/billing',
  },
};

export const redirects = {
  main: '/',
  knowYourCustomer: '/know-your-customer',
  settings: {
    generalSettings: '/settings/general',
  },
  profile: '/profile',
};
