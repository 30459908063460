import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CheckCircleIcon from '@untitled-ui/icons-react/build/esm/CheckCircle';
import CheckVerifiedIcon from '@untitled-ui/icons-react/build/esm/CheckVerified01';

import { Divider, Radio } from 'antd';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { setAbnSearchIdentifier } from 'api/client-side-slices/GroupsSlice';
import { CompanyData, useSearchProviderMutation } from 'api/EntitiesApiSlice';
import { useAppDispatch } from 'app-redux/hooks';
import { BaseButton, BaseSelect, UboProgress } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { Input } from 'components/Inputs/BaseInput';

import './AddCompanySteps.scss';

interface AddCompanyStepProps {
  handleDisplayModal: () => void;
}

export const AddCompanyStep = ({ handleDisplayModal }: AddCompanyStepProps) => {
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<number | null>(null);

  const [searchProvider, { data: abnData = [], isLoading }] = useSearchProviderMutation();
  const dispatch = useAppDispatch();

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    setError,
  } = useFormContext();

  const checkAbnField = watch('checkAbnField');

  //TODO WIP - Add API check options
  const apiCheckOptions = [
    { value: 'uboCheck', label: 'Run UBO Check' },
    { value: 'asicSearch', label: 'Run ASIC Search' },
  ];

  const handleSearch = async () => {
    const currentCheckAbnField = getValues('checkAbnField');

    if (currentCheckAbnField) {
      try {
        await searchProvider({ searchTerm: currentCheckAbnField }).unwrap();
      } catch {
        setError('checkAbnField', { message: 'Invalid ACN/ABN number' });
      }
    }
  };

  const handleCompanySelect = (index: number) => {
    setSelectedCompanyIndex(index === selectedCompanyIndex ? null : index);
    const selectedCompany = abnData[index];
    const identifier = selectedCompany.acn
      ? {
          entityIdentifier: selectedCompany.acn ?? '',
          identifierType: 'acn',
        }
      : {
          entityIdentifier: selectedCompany.abn ?? '',
          identifierType: 'abn',
        };
    dispatch(setDrawerCloseDisabled(false));
    dispatch(setAbnSearchIdentifier(identifier));
  };

  useEffect(() => {
    if (abnData?.length === 1) {
      setSelectedCompanyIndex(0);
      dispatch(setDrawerCloseDisabled(false));

      const entityIdentifier = abnData[0]?.acn || abnData[0]?.abn;

      if (entityIdentifier) {
        dispatch(
          setAbnSearchIdentifier({
            entityIdentifier,
            identifierType: abnData[0]?.acn ? 'acn' : 'abn',
          })
        );
      }
    } else {
      setSelectedCompanyIndex(null);
      dispatch(setDrawerCloseDisabled(true));
    }
  }, [abnData, isLoading]);

  const renderCompanyDetails = (company: CompanyData) => {
    const { abn, acn, entityType, location, registeredName, status } = company;
    return (
      <div className="bg-gray-50 gap-16 text-gray-500 p-5 font-normal text-xs rounded-lg h-fit w-full flex mt-2">
        <div className="flex flex-col gap-5">
          <span>Company Name</span>
          <span>ACN</span>
          <span>ABN</span>
          <span>Status</span>
          <span>Type</span>
          <span>Locality</span>
        </div>

        <div className="flex flex-col gap-5 font-semibold text-gray-800">
          <span className="uppercase">{registeredName || 'N/A'}</span>
          <span>{acn || 'N/A'}</span>
          <span>{abn || 'N/A'}</span>
          <span>{status || 'N/A'}</span>
          <span>{entityType || 'N/A'}</span>
          <span className="uppercase">{location || 'N/A'}</span>
        </div>
      </div>
    );
  };

  const renderCompanySelection = () => {
    if (abnData.length === 1) {
      return renderCompanyDetails(abnData[0]);
    }

    return (
      <div className="flex flex-col gap-4 w-full">
        <span className="text-gray-600" hidden={isLoading || !abnData.length}>
          Please select the company for which you want to start the verification process and
          continue to the next step in order to see the stakeholders.{' '}
        </span>
        <Radio.Group value={selectedCompanyIndex} className="w-full p-2">
          {abnData.map((company, index) => (
            <div key={index} className="mb-2.5">
              <Radio
                value={index}
                className="flex w-full items-center bg-gray-50 p-2.5 rounded-lg"
                onChange={() => handleCompanySelect(index)}
              >
                <div className="flex flex-col justify-start">
                  <span className="font-semibold text-gray-800">
                    {company.registeredName || 'N/A'}
                  </span>
                  <span className="text-gray-900">ABN: {company.abn || 'N/A'}</span>
                </div>
              </Radio>
              {selectedCompanyIndex === index && renderCompanyDetails(company)}
            </div>
          ))}
        </Radio.Group>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-between overflow-auto h-[calc(100vh-150px)] mb-[20px]">
      <div className="flex justify-start items-start flex-col ml-1.5 mr-1.5 gap-[20px]">
        <span className="font-bold text-2xl text-gray-800">Start New Verification</span>
        <Divider className="m-0" />
        <div className="text-gray-600 text-base font-semibold w-full flex justify-between">
          Add New Company
          <DeleteButton onClick={handleDisplayModal} />
        </div>
        <div className="w-full flex justify-center items-center">
          <UboProgress uboClassName="bg-primary-700 text-white border-blue-700" />
        </div>

        <span className="text-gray-600">
          Please type in the ACN/ABN of the company in order to do the UBO check. The check may take
          up to a minute.
        </span>

        <div className="flex justify-between w-full gap-2.5">
          <div className="flex w-full flex-col">
            <Controller
              name="checkAbnField"
              control={control}
              rules={{
                required: 'Please enter a valid ACN/ABN',
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Insert ACN/ABN..."
                  className={`h-10 ${errors.checkAbnField ? 'border-red-600' : ''}`}
                />
              )}
            />

            {errors.checkAbnField && (
              <p className="text-xs text-red-700 mt-1.5">
                {typeof errors.checkAbnField?.message === 'string'
                  ? errors.checkAbnField.message
                  : ''}
              </p>
            )}
          </div>

          <BaseButton
            className="secondary-button w-[172px]"
            loading={isLoading}
            onClick={handleSearch}
            icon={<CheckVerifiedIcon />}
            disabled={!checkAbnField}
          >
            Check Company
          </BaseButton>
        </div>

        <div className="flex flex-col w-full">
          <span
            className="flex gap-2 items-center text-success-700 pb-3"
            hidden={isLoading || !abnData.length}
          >
            <CheckCircleIcon className="h-4 w-4" /> Check successful!
          </span>
          {renderCompanySelection()}
          <span
            className="text-gray-600 pt-3 text-xs italic"
            hidden={isLoading || !abnData.length || abnData.length > 1}
          >
            For manual processes, please use the Trust/Other flow.{' '}
          </span>

          <Controller
            name="apiCheckSelect"
            control={control}
            rules={{ required: 'Please select an option' }}
            render={({ field }) => (
              <>
                <div
                  className="flex gap-1 items-center text-gray-600 pt-[28px]"
                  hidden={isLoading || !abnData.length || abnData.length > 1}
                >
                  Select what you want to do next:
                  <BaseSelect
                    {...field}
                    options={apiCheckOptions}
                    className="w-80"
                    placeholder="Select option"
                    // value={field.value} // Controlled value
                    // onChange={field.onChange} // Controlled onChange handler
                  />
                </div>
                <p
                  className="text-gray-600 pt-5"
                  hidden={
                    !field.value ||
                    field.value !== 'uboCheck' ||
                    isLoading ||
                    !abnData.length ||
                    abnData.length > 1
                  }
                >
                  Warning: the UBO check may take up to a minute.
                </p>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};
