import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { Player } from '@lottiefiles/react-lottie-player';
import PlusSquare from '@untitled-ui/icons-react/build/esm/PlusSquare';

import { Divider, notification, Table } from 'antd';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { setExistingCompany, setUboReportData } from 'api/client-side-slices/GroupsSlice';
import { useLazyGetEntityByIdQuery, useSearchAsicMutation } from 'api/EntitiesApiSlice';
import { useGetUboFlowByIdMutation, useGetUboFlowMutation } from 'api/UboFlowsApiSlice';
import { useAppDispatch, useAppSelector } from 'app-redux/hooks';
import { BaseButton, TableRowComponent, UboProgress } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { IndividualFormFields } from 'pages/KnowYourCustomer/AddNewTrust/components/AddTrustIndividualForm';
import { GetEntityRoleModel, UboGetReportResponse } from 'solstice-types';
import { EntityRole, isErrorWithStatus } from 'utils';
import { companyDrawerIndividualColumns, existingEntityDrawerColumns } from 'utils/constants';
import { ANIMATION_LOADING_JSON } from 'utils/icons';

import './AddCompanySteps.scss';

interface AddCompanyStepProps {
  setStep: (step: number) => void;
  isExistingCompany?: boolean;
  entityRoles?: GetEntityRoleModel[];
  handleDisplayModal: () => void;
}

export const AddCompanyIndividualsStep = ({
  setStep,
  isExistingCompany,
  handleDisplayModal,
  entityRoles,
}: AddCompanyStepProps) => {
  const [isExistingCompanyState] = useState<boolean>(isExistingCompany ?? false);

  const [fetchAbn] = useGetUboFlowMutation();
  const [getUboFlowById, { data: uboReportdata }] = useGetUboFlowByIdMutation();
  const [getAsicSearch, { data: asicData }] = useSearchAsicMutation();
  const [getEntityById, { data: entityData }] = useLazyGetEntityByIdQuery();

  const dispatch = useAppDispatch();
  const { abnIdentifier } = useAppSelector((state) => state.groups);
  const { register } = useForm();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'individuals',
  });
  const { apiCheckSelect } = getValues() ?? {};
  const dataSource = uboReportdata ?? asicData;
  const roleOptions = (entityRoles || []).map((role: EntityRole) => ({
    value: role.id,
    label: role.name,
  }));

  const handleAddNewForm = () => {
    append({
      firstName: '',
      lastName: '',
      role: '',
    });
  };

  const handleDeleteForm = (index: number) => {
    const currentIndividual = getValues('companyIndividual') || [];
    const updatedIndividual = currentIndividual.filter((_: any, i: number) => i !== index);
    setValue('companyIndividual', updatedIndividual);

    const updatedFields = Object.entries(getValues()).reduce<Record<string, any>>(
      (acc, [key, value]) => {
        if (key.includes(`_${index}`)) {
          return acc;
        }

        const adjustedKey = key.replace(new RegExp(`_(\\d+)`), (_, idx) =>
          idx > index ? `_${idx - 1}` : `_${idx}`
        );

        acc[adjustedKey] = value;
        return acc;
      },
      {}
    );

    reset(updatedFields);

    remove(index);
  };

  const stakeholderStyle = dataSource ? 'bg-primary-700 text-white border-primary-700' : '';
  const uboStyle = dataSource ? 'bg-primary-700 text-white border-primary-700' : '';

  const expandedRowRender = (record: any, index: number) => {
    const userData = dataSource?.report?.ubo?.beneficialOwners?.owners?.[index] || null;

    return <TableRowComponent record={record} userData={userData} index={index} />;
  };

  const dataSourceWithKeys = dataSource?.report?.ubo?.beneficialOwners?.owners?.map(
    (item, index) => ({
      ...item,
      key: `${index}`,
    })
  );

  const entityPersonsWithKeys = entityData?.persons?.map((person, index) => ({
    ...person,
    key: `${index + (dataSourceWithKeys?.length || 0)}`,
  }));

  const handleGetEntityById = async () => {
    try {
      const entity = await getEntityById({ id: abnIdentifier.entityId ?? '' }).unwrap();
      dispatch(setUboReportData(entity.persons as UboGetReportResponse));
    } catch {
      //TODO: Handle error
    }
  };

  useEffect(() => {
    let intervalId: number | undefined;

    const fetchData = async () => {
      try {
        let uboFlowResponse: any;

        if (apiCheckSelect !== undefined && apiCheckSelect === 'uboCheck') {
          const uboFlowResponse = await fetchAbn({
            entityIdentifier: abnIdentifier.entityIdentifier || '',
            identifierType: abnIdentifier.identifierType === 'acn' ? 'acn' : 'abn',
          }).unwrap();

          dispatch(setUboReportData(uboFlowResponse as UboGetReportResponse));

          if (uboFlowResponse.id) {
            await getUboFlowByIdWithRetry(uboFlowResponse.id);
          }
        } else if (apiCheckSelect === 'asicSearch') {
          uboFlowResponse = await getAsicSearch({
            searchTerm: abnIdentifier.entityIdentifier || '',
          })
            .unwrap()
            .catch(() => {
              setStep(1);
              notification.error({
                message: 'Company not found in ASIC Registry.',
                className: 'custom-error-message w-fit',
                placement: 'top',
                icon: <></>,
              });
            });
        } else {
          handleGetEntityById();
        }

        if (uboFlowResponse.id) {
          await getUboFlowByIdWithRetry(uboFlowResponse.id);
        }
      } catch {
        //TODO: Handle error
      }
    };

    const getUboFlowByIdWithRetry = async (id: string) => {
      try {
        const uboFlowByIdResponse = await getUboFlowById({ id }).unwrap();

        if (uboFlowByIdResponse) {
          dispatch(setUboReportData(uboFlowByIdResponse));

          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      } catch (error) {
        dispatch(setExistingCompany(null));
        if (isErrorWithStatus(error)) {
          if (error?.originalStatus === 500) {
            intervalId = window.setInterval(() => getUboFlowByIdWithRetry(id), 20000);
          } else {
            notification.error({
              message: `Error occurred: ${error?.message}`,
              className: 'custom-error-message',
              placement: 'top',
            });
          }
        }
      }
    };

    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [abnIdentifier.entityIdentifier, abnIdentifier.identifierType, apiCheckSelect]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    dispatch(setDrawerCloseDisabled(true));

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(setExistingCompany(null));
  }, []);

  const displayTableColumns =
    entityData !== undefined ? existingEntityDrawerColumns : companyDrawerIndividualColumns;
  return (
    <div className="flex flex-col justify-between overflow-auto h-[calc(100vh-150px)] mb-[20px]">
      <div className="flex justify-start items-start flex-col ml-1.5 mr-1.5 gap-[20px]">
        <span className="font-bold text-2xl text-gray-800">Start New Verification</span>
        {!isExistingCompanyState && <Divider className="m-0" />}
        <div
          className="text-gray-600 text-base font-semibold w-full flex justify-between"
          hidden={isExistingCompanyState}
        >
          Add New Company
          <DeleteButton onClick={handleDisplayModal} />
        </div>

        <div className="w-full flex justify-center items-center" hidden={isExistingCompanyState}>
          <UboProgress
            uboClassName="bg-primary-700 text-white border-primary-700"
            stakeholderClassName={stakeholderStyle}
            dividerClassName={uboStyle}
          />
        </div>

        {(dataSource ?? entityData) ? (
          <>
            <span className="text-gray-600">
              You can start the ID verification for the current stakeholders and also add extra
              individuals if needed.
            </span>
            <div className="flex flex-col gap-1">
              <span className="text-base text-gray-600 font-semibold">Beneficial Owners</span>
              <span className="text-gray-600 pb-2">
                The following individuals directly or indirectly own 25% or more of this entity.
                <br />
              </span>
              <span className="text-gray-600">
                <span hidden={isExistingCompany}>You can see or download the full report by</span>
                <a
                  hidden={isExistingCompany}
                  href="https://gbg-greenid.com/"
                  className="text-blue-500 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  clicking here.
                </a>
              </span>
            </div>

            <Table
              columns={displayTableColumns}
              dataSource={dataSourceWithKeys ?? entityPersonsWithKeys}
              className={`w-full company-individual-table no-expand-icon`}
              pagination={false}
              expandable={{
                expandedRowRender: dataSource ? expandedRowRender : undefined,
                defaultExpandAllRows: true,
                expandIcon: () => null,
                expandedRowClassName: () => 'bg-white border-none',
                expandedRowKeys: dataSourceWithKeys?.map((item) => item.key),
              }}
            />
            <span hidden={!fields.length} className="text-gray-600 text-base font-semibold">
              Add New Individual
            </span>

            {fields.map((field, index) => (
              <div key={field.id}>
                <input {...register(`individuals.${index}.value`)} />
                <div className="text-gray-600 font-semibold text-base flex items-center justify-between w-full min-w-[40.8rem]">
                  Person #{index + 1}
                  <DeleteButton onClick={() => handleDeleteForm(index)} />
                </div>

                <IndividualFormFields
                  control={control}
                  errors={errors}
                  roleOptions={roleOptions}
                  formIndex={index}
                  setValue={setValue}
                />
              </div>
            ))}

            <BaseButton type="link" className="text-blue-950 pl-0 pt-0" onClick={handleAddNewForm}>
              <PlusSquare width={15} height={15} />
              Add New Individual
            </BaseButton>
          </>
        ) : (
          <div className="flex w-full justify-center">
            <Player
              autoplay
              loop
              src={ANIMATION_LOADING_JSON}
              style={{ height: '186px', width: '422px' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
