// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown-btn {
  color: white;
  font-weight: 600;
  height: 40px;
  width: 209px;
}

.custom-dropdown-btn:hover {
  background-color: #1d5699 !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/DropdownMenu/DropdownMenu.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,oCAAA;EACA,uBAAA;AACF","sourcesContent":[".custom-dropdown-btn {\n  color: white;\n  font-weight: 600;\n  height: 40px;\n  width: 209px;\n}\n\n.custom-dropdown-btn:hover {\n  background-color: #1d5699 !important;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
