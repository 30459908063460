import React from 'react';

import { BaseButton } from 'components';

interface DuplicateEntityModalProps {
  onCancel: () => void;
  onUpdate: () => void;
  isLoading?: boolean;
  bodyContent: React.ReactNode;
  buttonText?: string;
}

export const ModalComponent = ({
  onCancel,
  onUpdate,
  isLoading,
  bodyContent,
  buttonText = 'Update Individual Data',
}: DuplicateEntityModalProps) => {
  return (
    <div className="font-semibold text-gray-900 text-lg max-w-[400px]">
      <p className="text-gray-600 text-sm font-normal">{bodyContent}</p>

      <div className="gap-2.5 flex justify-between w-full pt-8">
        <BaseButton className="secondary-button min-w-[112px]" onClick={onCancel}>
          Cancel
        </BaseButton>

        <BaseButton
          loading={isLoading}
          disabled={isLoading}
          className="primary-button bg-primary-600 text-white p-2 min-w-[120px]"
          onClick={onUpdate}
        >
          {buttonText}
        </BaseButton>
      </div>
    </div>
  );
};
