import { ReactNode, useState } from 'react';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';

import { Button, Dropdown, MenuProps } from 'antd';

import './DropdownMenu.scss';

export type DropdownMenuType = {
  buttonLabel: string;
  menuItems: ReactNode[];
};

export const DropdownMenu = ({ buttonLabel, menuItems }: DropdownMenuType) => {
  const [open, setOpen] = useState(false);

  const items: MenuProps['items'] = menuItems.map((item, index) => ({
    key: index.toString(),
    label: <span>{item}</span>,
  }));

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
      onOpenChange={handleOpenChange}
      open={open}
    >
      <Button
        className="custom-dropdown-btn"
        style={{
          backgroundColor: open ? '#1D5699' : '#2562A9',
          border: 'none',
        }}
      >
        <span>{buttonLabel}</span>
        {open ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5" />}
      </Button>
    </Dropdown>
  );
};
