import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CalendarIcon from '@untitled-ui/icons-react/build/esm/CalendarPlus01';
import HomeLine from '@untitled-ui/icons-react/build/esm/HomeLine';

import { Spin } from 'antd';
import { useGetIndividualByIdQuery } from 'api/IndividualsApiSlice';
import { BaseButton, Breadcrumb, StatusTag, TableComponent, useModal } from 'components';
import { format } from 'date-fns';
import { GetPersonModel } from 'solstice-types';
import { formatDisplayPhoneNumber, getFullName } from 'utils';
import { individualDetailsColumns } from 'utils/constants';
import { StatusChangeModal } from './components/StatusChangeModal';

export const IndividualDetails = () => {
  const { id = '' } = useParams<{ id: string }>();
  const [selectedIndividual, setSelectedIndividual] = useState<GetPersonModel | null>(null);

  const { data: individualData, isLoading } = useGetIndividualByIdQuery(id);

  const {
    firstName,
    lastName,
    status = null,
    email,
    mobilePhone,
    createdAt,
    dateOfBirth,
    middleName,
    lastKycId = '',
    relatedPersons = [],
  } = individualData ?? {};

  const individualName = getFullName(firstName, lastName);
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const handleDisplayModal = useCallback(() => {
    if (!selectedIndividual) return;

    openModal(<StatusChangeModal selectedIndividual={selectedIndividual} lastKycId={lastKycId} />);
  }, [selectedIndividual, openModal, closeModal]);

  if (isLoading) {
    return <Spin fullscreen className="translate-x-16" />;
  }

  const breadcrumbItems = [
    {
      title: <HomeLine className="h-5 w-5 text-gray-500" />,
    },
    {
      title: 'Know Your Customer',
      href: '/know-your-customer',
      className: 'text-gray-600 font-medium',
    },
    {
      title: individualName,
      className: 'font-semibold text-blue-600',
    },
  ];
  const createdAtFormat = createdAt
    ? format(new Date(Date.parse(createdAt)), 'dd/MM/yyyy - hh:mm a')
    : '';
  return (
    <div className="pt-8 h-screen">
      <div className="w-full border-b px-5 pb-6">
        <Breadcrumb items={breadcrumbItems} />
        <div className="mt-5 flex justify-between font-bold text-2xl text-gray-800">
          <div className=" flex items-center gap-2.5">
            {individualName}
            <StatusTag status={status} />
          </div>

          <div className="flex gap-2.5">
            <BaseButton
              className="secondary-button"
              onClick={() => navigate(`/entity-details/${id}/audit-log`)}
            >
              View Audit Log
            </BaseButton>
          </div>
        </div>
        <div className="text-gray-600 text-sm font-normal flex items-center gap-2">
          <CalendarIcon className="h-4 w-4 text-gray-400" />
          <span> Added on: {createdAtFormat} </span>
        </div>
      </div>

      <div className="w-full p-2.5 bg-gray-100 overflow-y-auto h-[calc(100vh-162px)]">
        <div className="w-full p-4 bg-white rounded-lg flex flex-col">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-semibold text-base text-gray-600">ID Verification</span>
              <span className="text-gray-600 text-sm pb-5 pt-1">
                Once the verification is complete, you will be able to see the full report here.
                Warning: the verification link is available for 48 hours only!
              </span>
            </div>
          </div>

          <TableComponent
            columns={individualDetailsColumns(handleDisplayModal)}
            dataSource={[individualData]}
            hasPagination={false}
            onRow={(record) => ({
              onClick: () => setSelectedIndividual(record),
            })}
          />
        </div>

        <div className="w-full p-4 mt-2.5 bg-white rounded-lg flex flex-col">
          <span className="font-semibold text-base text-gray-600">Individual Info</span>
          <span className="text-gray-600 text-sm pb-5 pt-1">
            Summary information found on this individual.
          </span>

          <div className="bg-gray-50 gap-40 text-gray-500 p-5 font-normal text-xs rounded-lg h-fit w-full flex">
            <div className="flex gap-5 min-w-40">
              <div className="flex flex-col gap-5">
                <span>First Name</span>
                <span>Email</span>
              </div>

              <div className="flex flex-col font-semibold text-gray-800 gap-5">
                <span>{firstName || '-'}</span>
                <span>{email ?? '-'}</span>
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex flex-col gap-5 justify-between">
                <span>Middle Name</span>
                <span>Phone Number</span>
              </div>

              <div className="flex flex-col font-semibold text-gray-800 gap-5">
                <span>{middleName !== '' ? middleName : '-'}</span>
                <span>{formatDisplayPhoneNumber(mobilePhone) ?? '-'}</span>
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex flex-col gap-5">
                <span>Last Name</span>
                <span>Date of Birth</span>
              </div>

              <div className="flex flex-col font-semibold text-gray-800 gap-5">
                <span>{lastName ?? '-'}</span>
                <span>{dateOfBirth ?? '-'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full p-4 mt-2.5 bg-white rounded-lg flex flex-col">
          <span className="font-semibold text-base text-gray-600">Associated Parties</span>
          <span className="text-gray-600 text-sm pb-5 pt-1">
            {relatedPersons && relatedPersons?.length > 0
              ? 'Below is the list and details of all associated parties'
              : 'No associated parties found.'}
          </span>

          {relatedPersons?.map((person, index) => (
            <div key={person.id} className="mb-5">
              <span className="text-gray-600 text-sm font-semibold">Person #{index + 1}</span>
              <div className="bg-gray-50 gap-40 text-gray-500 p-5 mt-5 font-normal text-xs rounded-lg h-fit w-full flex ">
                <div className="flex gap-5 min-w-52">
                  <div className="flex flex-col gap-5">
                    <span>First Name</span>
                    <span>Email</span>
                  </div>

                  <div className="flex flex-col font-semibold text-gray-800 gap-5">
                    <span>{person.firstName || '-'}</span>
                    <span>{person.email || '-'}</span>
                  </div>
                </div>

                <div className="flex gap-5 min-w-44">
                  <div className="flex flex-col gap-5 justify-between">
                    <span>Middle Name</span>
                    <span>Phone Number</span>
                  </div>

                  <div className="flex flex-col font-semibold text-gray-800 gap-5">
                    <span>{middleName !== '' ? middleName : '-'}</span>
                    <span>{formatDisplayPhoneNumber(person.mobilePhone) ?? '-'}</span>
                  </div>
                </div>

                <div className="flex gap-5">
                  <div className="flex flex-col gap-5">
                    <span>Last Name</span>
                    <span>Date of Birth</span>
                  </div>

                  <div className="flex flex-col font-semibold text-gray-800 gap-5">
                    <span>{person.lastName || '-'}</span>
                    <span>{person.dateOfBirth || '-'}</span>
                  </div>
                </div>

                <div className="flex gap-5">
                  <div className="flex flex-col gap-5">
                    <span>Relationship</span>
                  </div>

                  <div className="flex flex-col font-semibold text-gray-800 gap-5">
                    <span>{person.role || '-'}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
