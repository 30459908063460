// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-input-group-addon {
  display: none;
}

input {
  color: #0f172a !important;
}
input::placeholder {
  color: #697586;
  opacity: 1;
}
input:hover, input:focus {
  border-color: #2562a9 !important;
}
input:active {
  border-color: #2562a9 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Inputs/SearchInputs.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAqBA;EACE,yBAAA;AAlBF;AAoBE;EACE,cAAA;EACA,UAAA;AAlBJ;AAqBE;EAEE,gCAAA;AApBJ;AAuBE;EACE,gCAAA;AArBJ","sourcesContent":[".ant-input-group-addon {\n  display: none;\n}\n\n// .ant-input.ant-input-outlined.ant-select-selection-search-input {\n//   border-color: #cdd5df !important;\n//   color: #697586 !important;\n\n//   &::placeholder {\n//     color: #697586;\n//     opacity: 1;\n//   }\n\n//   &:hover,\n//   &:focus {\n//     border-color: #2562a9 !important;\n//   }\n\n//   &:active {\n//     border-color: #2562a9 !important;\n//   }\n// }\n\ninput {\n  color: #0f172a !important;\n\n  &::placeholder {\n    color: #697586;\n    opacity: 1;\n  }\n\n  &:hover,\n  &:focus {\n    border-color: #2562a9 !important;\n  }\n\n  &:active {\n    border-color: #2562a9 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
