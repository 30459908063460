import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { X_API_KEY } from 'api/ApiConfig';
import { Mutex } from 'async-mutex';
import axios from 'axios';
import {
  clearToken,
  getCookieValue,
  getRefreshToken,
  getUserId,
  navigateToLoginLegacyApp,
  setAccessToken,
  setRefreshToken,
} from 'utils/helpers';
import designSliceReduces from '../api/client-side-slices/DesignSlice';
import groupsSliceReducer from '../api/client-side-slices/GroupsSlice';

const mutex = new Mutex();

const baseQueryPrime = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PRIME_COMPLIANCE_API_URL,
  prepareHeaders: async (headers) => {
    headers.set('X-Api-Key', X_API_KEY);
    headers.set('user-id', getCookieValue('userName'));
    headers.set('token', getCookieValue('token'));
    return headers;
  },
});

const MAX_RETRIES = 4;

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  arg,
  api,
  extraOptions
) => {
  const retries = 0;
  let response;

  while (retries < MAX_RETRIES) {
    response = await baseQueryPrime(arg, api, extraOptions);
    const { error } = response;
    const storedRefreshToken = getRefreshToken();
    const is400 = error && error.status === 400;
    const is401 = error && error.status === 401;

    if (is401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          if (!storedRefreshToken) {
            localStorage.clear();
            navigateToLoginLegacyApp();
            break;
          } else {
            const refreshResult: any = await baseQueryPrime(
              {
                url: '/auth/refresh-token',
                method: 'POST',
                body: {
                  userId: getUserId(),
                  refreshToken: storedRefreshToken,
                },
                headers: {
                  'Content-Type': 'application/json',
                },
              },
              api,
              extraOptions
            );

            if ('data' in refreshResult) {
              const { accessToken, refreshToken } = refreshResult.data;

              if (!accessToken || !refreshToken) {
                localStorage.clear();
                navigateToLoginLegacyApp();
                break;
              } else {
                setAccessToken(accessToken);
                setRefreshToken(refreshToken);
                continue;
              }
            } else {
              localStorage.clear();
              navigateToLoginLegacyApp();
              break;
            }
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        continue;
      }
    }

    if (is400) {
      clearToken();
    }

    break;
  }

  return response as any;
};

export const API = () => {
  // const jwt: string | null = getAccessToken()

  const defaultHeaders = {
    'X-Api-Key': X_API_KEY,
    'user-id': 'cristi.oanca@techquarter.io',
  };

  //WIP TODO
  const defaultConfig = {
    baseURL:
      'https://prime-shared-kyc-api-dev.wittysmoke-af9c7a18.australiaeast.azurecontainerapps.io/api',

    headers: defaultHeaders,
  };

  return axios.create(defaultConfig);
};

export const apiPrimeSlice = createApi({
  reducerPath: 'primeApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Groups',
    'Individuals',
    'EntityRoles',
    'IndividualsById',
    'PrimeSearch',
    'GetEntityById',
  ],
  endpoints: () => ({}),
});

const rootReducer = combineReducers({
  [apiPrimeSlice.reducerPath]: apiPrimeSlice.reducer,
  groups: groupsSliceReducer,
  design: designSliceReduces,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(apiPrimeSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
