import { ReactNode } from 'react';
import ChevronRight from '@untitled-ui/icons-react/build/esm/ChevronRight';

import { Breadcrumb as AntBreadcrumb } from 'antd';

interface BreadcrumbProps {
  individualName?: string;
  items?: { title: ReactNode; href?: string; className?: string }[];
}

export const Breadcrumb = ({ individualName, items }: BreadcrumbProps) => {
  const defaultItems: any = [];

  const breadcrumbItems = individualName
    ? [...defaultItems, { title: individualName, className: 'font-semibold text-blue-600' }]
    : defaultItems;

  return (
    <AntBreadcrumb
      separator={<ChevronRight className="h-4 w-4 text-gray-300 mt-1 mx-1" />}
      className="text-gray-600"
      items={[...breadcrumbItems, ...(items ?? [])]}
    />
  );
};
