// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-button {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(238 242 246 / var(--tw-text-opacity))
}
.nav-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 90 156 / var(--tw-bg-opacity))
}`, "",{"version":3,"sources":["webpack://./src/pages/Navigation.scss"],"names":[],"mappings":"AACI;IAAA,aAAA;IAAA,eAAA;IAAA,mBAAA;IAAA,qBAAA;IAAA,qBAAA;IAAA,sBAAA;IAAA,mBAAA;IAAA,sBAAA;IAAA,eAAA;IAAA,gBAAA;IAAA,oBAAA;IAAA;AAAA;AAAA;IAAA,kBAAA;IAAA;AAAA","sourcesContent":[".nav-button {\n    @apply flex items-center px-3 py-2 text-grayModern-100 rounded-lg hover:bg-[#1e5a9c] font-medium text-[14px] cursor-pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
