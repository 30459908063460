import FileAttachmentIcon from '@untitled-ui/icons-react/build/esm/FileAttachment04';
import UserPlus01 from '@untitled-ui/icons-react/build/esm/UserPlus01';

import { useAppSelector } from 'app-redux/hooks';
import { twMerge } from 'tailwind-merge';

interface UboProgressProps {
  trustClassName?: string;
  stakeholderClassName?: string;
  dividerClassName?: string;
}

export const TrustStakeholdersProgress = ({
  trustClassName,
  stakeholderClassName,
  dividerClassName,
}: UboProgressProps) => {
  const { currentStep } = useAppSelector((state) => state.design);

  const stakeholderFontStyle =
    currentStep === 1 ? 'font-semibold text-gray-600 text-sm' : 'font-normal';

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div
          className={`flex flex-col w-22 translate-x-5 font-semibold text-gray-600 text-sm' gap-1 items-center`}
        >
          <div
            className={twMerge(
              `h-10 w-10 p-2 border rounded-full flex items-center justify-center shadow-sm mt-5`,
              trustClassName
            )}
          >
            <FileAttachmentIcon className="h-5 w-5" />
          </div>
          Trust/Other Details
        </div>

        <div className={twMerge(`border-b-2 min-w-[296px] -translate-x-5`, dividerClassName)} />

        <div
          className={`flex flex-col w-26 -translate-x-14 gap-1 items-center ${stakeholderFontStyle}`}
        >
          <div
            className={twMerge(
              `h-10 w-10 p-2 border rounded-full flex items-center justify-center shadow-sm mt-5`,
              stakeholderClassName
            )}
          >
            <UserPlus01 className="h-5 w-5" />
          </div>
          Add Stakeholders
        </div>
      </div>
    </div>
  );
};
