import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GenericState {
  isDrawerCloseDisabled: boolean;
  currentStep: number;
}

const initialGenericState: GenericState = {
  isDrawerCloseDisabled: true,
  currentStep: 0,
};

const designSlice = createSlice({
  name: 'designSlice',
  initialState: initialGenericState,
  reducers: {
    setDrawerCloseDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDrawerCloseDisabled = action.payload;
    },

    setCurentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },

    resetState: () => initialGenericState,
  },
});

export const { setDrawerCloseDisabled, resetState, setCurentStep } = designSlice.actions;

export default designSlice.reducer;
