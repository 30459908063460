import { Radio } from 'antd';
import { BaseSelect, StatusTag, UserInitialsCircle } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { getFullName, highlightText } from 'utils';

interface SelectedIndividualProps {
  user: any;
  searchQuery: string;
  isExistingVisible?: boolean;
  areOptionsVisible?: boolean;
  handleDisplayInputs?: (event: any) => void;
  isStatusVisible?: boolean;
  isSelectVisible?: boolean;
  selectOptions?: any[];
  onSelect?: (value: string) => void;
  isCheckingEmail?: boolean;
  hasDateOfBirth?: boolean;
}

export const SelectedIndividual = ({
  user,
  searchQuery,
  isExistingVisible,
  areOptionsVisible,
  handleDisplayInputs,
  isStatusVisible = true,
  isSelectVisible = false,
  selectOptions = [],
  onSelect,
  isCheckingEmail,
  hasDateOfBirth = false,
}: SelectedIndividualProps) => {
  const hiddenStyle = !areOptionsVisible && !isCheckingEmail ? !isExistingVisible : false;
  const displayStyle = !areOptionsVisible ? 'rounded-md bg-gray-100 py-2.5 px-2' : '';

  const { firstName, lastName, mobilePhone, email, status } = user ?? {};

  return (
    <div
      className={`flex justify-between items-center w-full text-gray-500 ${displayStyle}`}
      hidden={hiddenStyle}
    >
      <div className="flex gap-2 items-center">
        {isCheckingEmail ? (
          <Radio type="radio" checked onChange={() => {}} />
        ) : (
          <UserInitialsCircle data={user} />
        )}
        <div className="flex flex-col">
          <span className="font-semibold text-sm">
            {highlightText(getFullName(firstName, lastName), searchQuery)}
          </span>
          <div className="font-normal text-xs">
            {`${email} -
            ${highlightText(mobilePhone ?? '', searchQuery)}`}
          </div>
        </div>
      </div>

      <div className="flex gap-2 items-center">
        {isSelectVisible && (
          <BaseSelect
            options={selectOptions}
            className="min-w-40"
            placeholder="Select relationship"
            onSelect={onSelect}
          />
        )}

        {/* {hasDateOfBirth && (
          <Controller
            name="selectedDob"
            control={control}
            render={({ field: controllerField }) => {
              return (
                <DatePicker
                  {...controllerField}
                  className="w-48 h-10"
                  suffixIcon={<CalendarDate className="h-5 text-gray-700" />}
                  placeholder="Select date of birth..."
                  maxDate={dayjs().subtract(0, 'month').endOf('month')}
                />
              );
            }}
          />
        )} */}

        {isStatusVisible && <StatusTag status={status ?? ''} />}

        <DeleteButton
          onClick={handleDisplayInputs}
          areOptionsVisible={areOptionsVisible || isCheckingEmail}
        />
      </div>
    </div>
  );
};
