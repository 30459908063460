import { useState } from 'react';
import CheckCircleIcon from '@untitled-ui/icons-react/build/esm/CheckCircle';
import CheckVerifiedIcon from '@untitled-ui/icons-react/build/esm/CheckVerified01';

import { Input } from 'antd';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { setUboReportData } from 'api/client-side-slices/GroupsSlice';
import { useSearchIndividualByEmailMutation } from 'api/IndividualsApiSlice';
import { useAppDispatch, useAppSelector } from 'app-redux/hooks';
import { BaseButton } from 'components/Buttons';
import { OwnerWithEmail } from 'pages/KnowYourCustomer/AddNewCompany/AddNewCompanyFlow';
import { SelectedIndividual } from 'pages/KnowYourCustomer/AddNewIndividual/components';

interface TableRowComponentProps {
  record: any;
  userData?: any;
  index: number;
}

export const TableRowComponent = ({ record, index }: TableRowComponentProps) => {
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [emailError, setEmailError] = useState<{ [key: string]: string }>({});

  const { uboReportData } = useAppSelector((state) => state.groups);
  const dispatch = useAppDispatch();

  const [searchIndividualByEmail, { data: emailData, isLoading }] =
    useSearchIndividualByEmailMutation();

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (key: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [key]: value }));
    setEmailError((prev) => ({ ...prev, [key]: '' }));

    if (value && !validateEmail(value)) {
      setEmailError((prev) => ({ ...prev, [key]: 'Please enter a valid email address' }));
    }
  };

  const handleCheckMatch = async (key: string) => {
    const email = inputValues[key];

    if (!email || !validateEmail(email)) {
      setEmailError((prev) => ({ ...prev, [key]: 'Please enter a valid email address' }));
      return;
    }

    try {
      const result = await searchIndividualByEmail({ email }).unwrap();

      if (!result || Object.keys(result).length === 0) {
        setNoMatchFound(true);
      } else {
        setNoMatchFound(false);
      }

      const updatedUboReportData = {
        ...uboReportData,
        report: {
          ...uboReportData?.report,
          ubo: {
            ...uboReportData?.report?.ubo,
            beneficialOwners: {
              ...uboReportData?.report?.ubo?.beneficialOwners,
              owners: uboReportData?.report?.ubo?.beneficialOwners?.owners?.map((owner, i) => {
                if (i === index) {
                  return { ...owner, email };
                }
                return owner;
              }),
            },
          },
        },
      };

      const allHaveEmails = updatedUboReportData.report.ubo.beneficialOwners.owners?.every(
        (owner: OwnerWithEmail) => owner.email && validateEmail(owner.email)
      );

      dispatch(setDrawerCloseDisabled(!allHaveEmails));
      dispatch(setUboReportData(updatedUboReportData));
    } catch {
      setNoMatchFound(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between w-full gap-2.5">
        <div className="flex flex-col flex-grow">
          <Input
            placeholder="Enter email address"
            value={inputValues[record.key] || ''}
            onChange={(e) => handleInputChange(record.key, e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleCheckMatch(record.key)}
            status={emailError[record.key] ? 'error' : ''}
            className="h-10"
          />

          {emailError[record.key] && (
            <div className="flex items-center gap-1 text-error-500 text-xs mt-1">
              {emailError[record.key]}
            </div>
          )}
        </div>
        <BaseButton
          className="min-w-[230px]"
          onClick={() => handleCheckMatch(record.key)}
          loading={isLoading}
          disabled={!inputValues[record.key] || !!emailError[record.key]}
        >
          <CheckVerifiedIcon />
          Check for Existing Match
        </BaseButton>
      </div>

      {emailData && (
        <div className="flex text-success-700 gap-2 my-2">
          <CheckCircleIcon className="h-4 w-4" /> Check successful! Match found.
        </div>
      )}

      {emailData && (
        <SelectedIndividual
          user={emailData}
          searchQuery={''}
          isCheckingEmail
          isStatusVisible={false}
          hasDateOfBirth
        />
      )}

      {noMatchFound && (
        <div className="flex text-success-700 gap-2 mt-2">
          <CheckCircleIcon className="h-4 w-4" /> Check successful! No match found.
        </div>
      )}
    </div>
  );
};
