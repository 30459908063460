import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Eye from '@untitled-ui/icons-react/build/esm/Eye';
import PlusSquare from '@untitled-ui/icons-react/build/esm/PlusSquare';

import { BaseButton, TrustStakeholdersProgress } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { roleOptions } from 'pages/KnowYourCustomer/AddNewIndividual/components/IndividualConstants';
import { UploadedFile } from 'utils';
import { IndividualFormFields } from '../components/AddTrustIndividualForm';

interface AddTrustIndividualsStepProps {
  setStep: (step: number) => void;
  uploadedFiles: UploadedFile[];
}

export const AddTrustIndividualsStep = ({
  setStep,
  uploadedFiles,
}: AddTrustIndividualsStepProps) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'individuals',
  });

  // Initialize with one empty individual if there are no fields
  useEffect(() => {
    if (fields.length === 0) {
      append({
        firstName: '',
        lastName: '',
        role: '',
      });
    }
  }, [append, fields.length]);

  const handleOpenFile = (file: File) => {
    const fileUrl = URL.createObjectURL(file);
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    } else {
      // TODO: Add error handling
    }
  };

  const handleAddNewIndividual = () => {
    append({
      firstName: '',
      lastName: '',
      role: '',
    });
  };

  const handleRemoveIndividual = (index: number) => {
    // Only allow removal if there will be at least one form remaining
    if (fields.length > 1) {
      const currentIndividual = getValues('trustIndividual') || [];
      const updatedIndividual = currentIndividual.filter((_: any, i: number) => i !== index);
      setValue('trustIndividual', updatedIndividual);

      const updatedFields = Object.entries(getValues()).reduce<Record<string, any>>(
        (acc, [key, value]) => {
          if (key.includes(`_${index}`)) {
            return acc;
          }

          const adjustedKey = key.replace(new RegExp(`_(\\d+)`), (_, idx) =>
            idx > index ? `_${idx - 1}` : `_${idx}`
          );

          acc[adjustedKey] = value;
          return acc;
        },
        {}
      );

      reset(updatedFields);

      remove(index);
    }
  };

  return (
    <div className="flex flex-col overflow-auto h-[calc(100vh-150px)] mb-[20px]">
      <div className="font-bold text-2xl text-gray-800 w-full">Start New Verification</div>

      <div className="flex justify-start items-start flex-col ml-1.5 mr-1.5 gap-5">
        <div className="h-px bg-gray-200 w-full my-2.5" />

        <div className="text-gray-600 font-semibold w-full flex justify-between">
          Add New Trust/Other
          <DeleteButton onClick={() => setStep(0)} />
        </div>

        <div className="w-full flex justify-center items-center">
          <TrustStakeholdersProgress
            trustClassName="bg-primary-700 text-white border-primary-700"
            stakeholderClassName="bg-primary-700 text-white border-primary-700"
            dividerClassName="border-lg border-primary-700"
          />
        </div>

        {uploadedFiles?.length > 0 && (
          <div className="flex flex-col">
            <span className="text-gray-600 text-base font-semibold pb-2.5">Attached Docs</span>
            <div className="flex flex-wrap gap-2.5">
              {uploadedFiles.map((file: any, index) => (
                <div
                  key={index}
                  className="bg-gray-100 flex items-center justify-between rounded-lg py-2.5 px-2 w-80 h-10"
                >
                  <span className="text-gray-800 truncate">{file.name}</span>
                  <BaseButton
                    className="min-w-8 min-h-8 p-1"
                    onClick={() => handleOpenFile(file)}
                    type="link"
                  >
                    <Eye />
                  </BaseButton>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="text-gray-600 flex flex-col">
          <span className="font-semibold text-gray-600 pb-1">Beneficial Owners</span>
          <span>
            Please add all the individuals who directly or indirectly own a percentage of this
            entity.
          </span>
        </div>

        {fields.map((field, index) => (
          <div key={field.id}>
            <div className="text-gray-600 font-semibold text-base flex items-center justify-between w-full min-w-[40.8rem]">
              Person #{index + 1}
              <DeleteButton
                onClick={() => handleRemoveIndividual(index)}
                disabled={fields.length === 1}
              />
            </div>

            <IndividualFormFields
              control={control}
              errors={errors}
              roleOptions={roleOptions}
              formIndex={index}
              setValue={setValue}
            />
          </div>
        ))}

        <BaseButton className="link-button" onClick={handleAddNewIndividual}>
          <PlusSquare className="h-4 w-4 mr-2" />
          Add New Individual
        </BaseButton>
      </div>
    </div>
  );
};
