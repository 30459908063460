import SearchMd from '@untitled-ui/icons-react/build/esm/SearchMd';
import UserPlus01 from '@untitled-ui/icons-react/build/esm/UserPlus01';

import { useAppSelector } from 'app-redux/hooks';

interface UboProgressProps {
  uboClassName?: string;
  stakeholderClassName?: string;
  dividerClassName?: string;
}

export const UboProgress = ({
  uboClassName,
  stakeholderClassName,
  dividerClassName,
}: UboProgressProps) => {
  const { currentStep } = useAppSelector((state) => state.design);

  const stakeholderStyleFont = currentStep === 2 ? 'font-semibold' : '';

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div
          className={`flex flex-col w-21 text-gray-700 translate-x-5 gap-1 items-center font-semibold`}
        >
          <div
            className={`h-10 w-10 p-2 border rounded-full flex items-center justify-center shadow-sm mt-5 ${uboClassName}`}
          >
            <SearchMd className="h-5 w-5" />
          </div>
          Company Check
        </div>

        <div
          className={`border-b-2 min-w-[310px] text-gray-700 -translate-x-2 ${dividerClassName}`}
        />

        <div
          className={`flex flex-col w-26 -translate-x-14 gap-1 items-center ${stakeholderStyleFont}`}
        >
          <div
            className={`h-10 w-10 p-2 border rounded-full flex items-center justify-center shadow-sm mt-5 ${stakeholderClassName}`}
          >
            <UserPlus01 className="h-5 w-5" />
          </div>
          Stakeholders Selection
        </div>
      </div>
    </div>
  );
};
