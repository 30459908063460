import React, { ChangeEvent, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import XCircleIcon from '@untitled-ui/icons-react/build/esm/XCircle';
import XCloseIcon from '@untitled-ui/icons-react/build/esm/XClose';

import { Divider, Input, Select } from 'antd';
import { BaseButton, TrustStakeholdersProgress } from 'components';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { trustTypesOptions } from 'utils/EntityTypes';

interface AddTrustStepProps {
  setStep: (step: number) => void;
  error: string;
}

export const AddTrustStep = ({ setStep, error }: AddTrustStepProps) => {
  const fileInputRef = useRef<any>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const trustOptions = trustTypesOptions?.map((item) => ({
    value: item.value,
    label: item.label,
  }));

  const {
    control,
    watch,
    formState: { errors },
    reset,
  } = useFormContext();

  const handleUploadClick = () => {
    setFileError(null);
    fileInputRef.current?.click();
  };

  const handleFileUpload = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (files: File[]) => void
  ) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    const currentFiles = watch('uploaded_files') || [];

    const oversizedFiles = newFiles.filter((file) => file.size > 50 * 1024 * 1024); // 50MB in bytes
    if (oversizedFiles.length > 0) {
      setFileError(
        'Error! File size exceeds the limit of 50Mb. Please select another file and try again.'
      );
      return;
    }

    setFileError(null);
    onChange([...currentFiles, ...newFiles]);
  };

  const handleRemoveFile = (fileToRemove: File, onChange: (files: File[]) => void) => {
    const currentFiles: File[] = watch('uploaded_files') || [];
    const updatedFiles = currentFiles.filter((file) => file !== fileToRemove);
    onChange(updatedFiles);
  };

  return (
    <div className="flex flex-col overflow-auto h-[calc(100vh-150px)] mb-[20px]">
      <div className="font-bold text-2xl text-gray-800 w-full">Start New Verification</div>

      <div className="flex justify-start items-start flex-col ml-1.5 mr-1.5 gap-[20px]">
        <Divider className="my-2.5" />

        <div className="text-gray-600 font-semibold w-full flex justify-between">
          Add New Trust/Other
          <DeleteButton
            onClick={() => {
              reset();
              setStep(0);
            }}
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <TrustStakeholdersProgress trustClassName="bg-primary-700 text-white border-blue-700" />
        </div>

        <div className="text-gray-600">
          Please insert the trust/other name and select the type of it.
        </div>

        <div className="flex gap-5 w-full">
          <div className="flex w-full flex-col">
            <label className="block mb-1 text-gray-600 font-semibold text-xs">Name *</label>
            <Controller
              name="formTrustName"
              control={control}
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <Input
                  {...field}
                  className="h-10 w-full"
                  status={errors['trust_name'] ? 'error' : ''}
                />
              )}
            />
          </div>

          <div className="flex w-full flex-col">
            <label className="block mb-1 text-gray-600 font-semibold text-xs">Type *</label>
            <Controller
              name="formTrustType"
              control={control}
              rules={{ required: 'Type is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Select"
                  className="h-10 w-full"
                  options={trustOptions}
                  status={errors['trust_name'] ? 'error' : ''}
                />
              )}
            />
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <span className="text-gray-600">
            Please upload the following documents in order to move to the next step.
          </span>
          <li className="text-gray-600">Trust Deed</li>
          <span>or</span>
          <ul className="list-disc list-inside text-gray-600">
            <li>a partnership agreement</li>
            <li>
              the constitution and/or certificate of incorporation for an incorporated association
            </li>
            <li>the constitution of a registered cooperative</li>
          </ul>
        </div>

        <Controller
          name="uploaded_files"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value = [] } }) => (
            <>
              <div
                className="bg-grayModern-50 shadow-md w-full flex flex-col items-center justify-center rounded-lg border py-2 cursor-pointer"
                onClick={handleUploadClick}
              >
                <span className="text-grayModern-800">Click to upload document</span>
                <span className="text-grayModern-500 text-xs">
                  Accepted file types: .pdf Max 50Mb per file.
                </span>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(event, onChange)
                  }
                  accept=".pdf"
                  multiple
                  style={{ display: 'none' }}
                />
              </div>

              {value?.map((file: File, index: number) => (
                <div
                  key={index}
                  className="bg-gray-100 w-full flex items-center justify-between rounded-lg py-2.5 px-2"
                >
                  <span className="text-grayModern-800">{file.name}</span>
                  <BaseButton
                    className="p-2 min-w-2 min-h-2"
                    onClick={() => handleRemoveFile(file, onChange)}
                    type="link"
                  >
                    <XCloseIcon />
                  </BaseButton>
                </div>
              ))}
            </>
          )}
        />

        {fileError && (
          <div className="text-error-600 mt-2 flex gap-2.5 items-center">
            <XCircleIcon className="h-4 w-4 text-error-600" /> {fileError}
          </div>
        )}
      </div>
    </div>
  );
};
