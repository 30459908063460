import { Tag } from 'antd';
import { twMerge } from 'tailwind-merge';
import { Status } from 'utils';
import { statusMap } from './StatusTagHelpers';

interface StatusTagProps {
  status: Status | null | string;
}

export const StatusTag = ({ status }: StatusTagProps) => {
  const statusKey = status || 'NotVerified';
  const { tailwindClass, text, icon } = statusMap[statusKey] || statusMap['NotVerified'];

  const mergedClasses = twMerge(
    'rounded-2xl gap-1 text-xs py-1 w-fit border-none inline-flex items-center font-medium px-2 ',
    tailwindClass
  );

  return (
    <Tag className={mergedClasses} icon={icon}>
      {text}
    </Tag>
  );
};
