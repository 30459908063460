import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import ArrowNarrowDownRight from '@untitled-ui/icons-react/build/esm/ArrowNarrowDownRight';
import ArrowNarrowUpLeft from '@untitled-ui/icons-react/build/esm/ArrowNarrowUpLeft';
import ChevronRightDouble from '@untitled-ui/icons-react/build/esm/ChevronRightDouble';

import { Button, Drawer } from 'antd';
import { setDrawerCloseDisabled } from 'api/client-side-slices/DesignSlice';
import { useAppDispatch } from 'app-redux/hooks';
import { Icon } from 'components';
import { DIVIDER_VERTICAL_ICON } from 'utils/icons';

import './Drawer.scss';

interface DrawerContextType {
  openDrawer: (
    drawerContent: ReactNode,
    title: string,
    onSave?: () => void,
    onCancel?: () => void,
    currentStep?: number
  ) => void;
  closeDrawer: () => void;
}

interface DrawerProviderProps {
  children: ReactNode;
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [content, setContent] = useState<ReactNode>(null);
  const [title, setTitle] = useState<string>('');
  const [drawerWidth, setDrawerWidth] = useState<string>('min-w-[720px]');

  const dispatch = useAppDispatch();

  const openDrawer = useCallback((drawerContent: ReactNode, drawerTitle: string) => {
    setContent(drawerContent);
    setTitle(drawerTitle);
    setIsVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsVisible(false);
    setContent(null);
    setTitle('');
    dispatch(setDrawerCloseDisabled(true));
  }, [dispatch]);

  const handleCancel = () => {
    setDrawerWidth('min-w-[720px]');
    closeDrawer();
  };

  const handleFullScreen = () => {
    setDrawerWidth((prevWidth) =>
      prevWidth === 'min-w-[720px]' ? 'min-w-[calc(100vw-224px)]' : 'min-w-[720px]'
    );
  };

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      {children}
      <Drawer
        placement="right"
        onClose={handleCancel}
        open={isVisible}
        closeIcon={null}
        title={title}
        className="pt-0"
        maskClosable={false}
        classNames={{ wrapper: `${drawerWidth}` }}
      >
        <div className="flex gap-1 h-10 pb-10 w-fit">
          <Button onClick={handleCancel} type="link" className="p-0">
            <ChevronRightDouble className="cursor-pointer h-5 w-5 text-gray-500" />
          </Button>

          <Icon src={DIVIDER_VERTICAL_ICON} alt="divider" className="mt-2" />

          <Button onClick={handleFullScreen} type="link" className="p-0">
            {drawerWidth === 'min-w-[720px]' ? (
              <ArrowNarrowUpLeft className="cursor-pointer h-5 w-5 text-gray-500 transition-opacity duration-300 transform" />
            ) : (
              <ArrowNarrowDownRight className="cursor-pointer h-5 w-5 text-gray-500 transition-opacity duration-300 transform" />
            )}
          </Button>
        </div>

        {content}
      </Drawer>
    </DrawerContext.Provider>
  );
};

export const useDrawer = (): DrawerContextType => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};
