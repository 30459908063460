/* eslint-disable max-lines */
import React from 'react';
import EditIcon03 from '@untitled-ui/icons-react/build/esm/Edit03';
import SettingsIcon from '@untitled-ui/icons-react/build/esm/Settings02';
import UserIcon from '@untitled-ui/icons-react/build/esm/User01';

import { Tooltip } from 'antd';
import { BaseButton, LinkCopyButton, StatusTag } from 'components';
import { RedirectButton } from 'components/Buttons/RedirectButton';
import { format } from 'date-fns';
import { GetEntityModel, GetPersonWithRolesModel } from 'solstice-types';
import { getCookieValue } from './helpers';
import { Status } from './interfaces';
import { getFullName } from './utils';

export const groupColumns = [
  {
    title: 'Group Name',
    dataIndex: 'registeredName',
    key: 'registeredName',
    width: '17%',
    render: (text: string, record: GetEntityModel) => (
      <div className="flex flex-col">
        <span className="font-medium text-gray-800 overflow-hidden whitespace-nowrap text-ellipsis">
          <Tooltip title={record.registeredName} placement="top">
            {record.registeredName}
          </Tooltip>
        </span>
        <span className="text-xs text-gray-500 font-medium">
          {record.entityPersonCount ? `${record.entityPersonCount} individuals` : ''}
        </span>
      </div>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'entityTypeName',
    key: 'entityTypeName',
    width: '17%',
  },
  {
    title: 'ABN',
    dataIndex: 'abn',
    key: 'abn',
    render: (record: string) => record ?? '-',
    width: '16%',
  },
  {
    title: 'Status',
    dataIndex: 'kycStatus',
    key: 'kycStatus',
    render: (record: Status) => <StatusTag status={record} />,
    width: '17%',
  },
  {
    title: 'Assigned To',
    dataIndex: 'assignedToUser',
    key: 'assignedToUser',
    width: '17%',
    render: (data: any) => {
      return data.userName ?? '-';
    },
  },
  {
    title: 'Last Updated',
    dataIndex: 'statusUpdatedAt',
    key: 'statusUpdatedAt',
    width: '17%',
    render: (record: string) => (
      <div className="flex flex-col">
        <span className="text-gray-800">{format(new Date(record), 'dd/MM/yyyy - hh:mm a')}</span>
        {/* <span className="text-gray-500 text-xs">by N/A</span> */}
      </div>
    ),
  },
];

export const individualColumns = [
  {
    title: 'Name',
    dataIndex: 'firstName',
    key: 'firstName',
    width: '17%',
    render: (text: string, record: GetPersonWithRolesModel) => (
      <div className="flex flex-col">
        <span className="font-medium text-gray-800">
          {record.firstName} {record.lastName}
        </span>
        <span className="text-xs text-gray-500 font-medium">
          {record.entityRoles?.length && record.entityRoles[0].roleName !== 'Creator'
            ? `${record.entityRoles[0]?.entityName}`
            : ''}
        </span>
      </div>
    ),
  },

  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '17%',
    render: (text: string, record: GetPersonWithRolesModel) => {
      return record.entityRoles && record.entityRoles.length > 0
        ? 'Multiple Individuals'
        : 'Single Individual';
    },
  },
  {
    title: 'ABN',
    dataIndex: 'abn',
    key: 'abn',
    width: '16%',
    render: (record: any) => {
      return record ?? '-';
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '17%',
    render: (status: string) => <StatusTag status={status ?? null} />,
  },
  {
    title: 'Assigned To',
    dataIndex: 'assignedToUser',
    key: 'assignedToUser',

    render: (record: any) => {
      if (!record) return '-';

      return record.userName ?? '-';
    },
  },
  {
    title: 'Last Updated',
    dataIndex: 'statusUpdatedAt',
    key: 'statusUpdatedAt',
    width: '17%',
    render: (record: string) => (
      <div className="flex flex-col">
        <span className="text-gray-800">{format(new Date(record), 'dd/MM/yyyy - hh:mm a')}</span>
        {/* <span className="text-gray-500 text-xs">by N/A</span> */}
      </div>
    ),
  },
];

export const companyIndividualsColumns = [
  {
    title: 'Individual Name',
    dataIndex: 'firstName',
    key: 'lastName',
    width: '20%',
    render: (_: any, record: any) => {
      return (
        <div className="overflow-hidden whitespace-nowrap text-ellipsis text-gray-900 font-medium">
          {getFullName(record?.firstName, record?.lastName) || ''}
        </div>
      );
    },
  },

  {
    title: 'Role',
    dataIndex: 'assignedToUser',
    key: 'assignedToUser',
    width: '20%',

    render: (_: any, record: any) => {
      if (!record) {
        return '-';
      }

      return (
        <div className="overflow-hidden whitespace-nowrap text-ellipsis min-w-40 max-w-full ">
          {record.roleName}
        </div>
      );
    },
  },
  {
    title: 'Beneficially Held',
    dataIndex: 'beneficiallyHeld',
    key: 'beneficiallyHeld',
    render: (text: any) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Total Ownership',
    dataIndex: 'ownershipPercentage',
    key: 'ownershipPercentage',
    render: (text: any) => {
      if (!text) return '-';
      const formattedText = parseFloat(text) === 100 ? '100%' : `${parseFloat(text)}%`;
      return formattedText;
    },
  },
];

export const companyDetailsColumns = (showModal: (record: any) => void) => [
  {
    title: 'Started On',
    dataIndex: 'lastKycFlowCreatedAt',
    key: 'lastKycFlowCreatedAt',
    render: (text: string) => format(new Date(text), 'dd/MM/yyyy - hh:mm a'),
  },
  {
    title: 'Assigned To',
    dataIndex: 'assignedToUser',
    key: 'assignedToUser',
    render: (record: any) => {
      if (!record) return '-';

      return record.userName ?? '-';
    },
  },
  {
    title: 'Verification Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => {
      return <StatusTag status={text} />;
    },
  },
  {
    title: 'Last Status Update',
    dataIndex: 'statusUpdatedAt',
    key: 'statusUpdatedAt',
    render: (text: string) => format(new Date(text), 'dd/MM/yyyy - hh:mm a'),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (text: string, record: any) => {
      return (
        <div className="flex gap-2.5 text-gray-700">
          <Tooltip title="Edit Verification Status" placement="top">
            <BaseButton
              className="min-h-8 min-w-8 p-0  secondary-button"
              onClick={() => showModal(record)}
            >
              <EditIcon03 className="h-4 w-4" />
            </BaseButton>
          </Tooltip>

          <RedirectButton record={record} />

          <LinkCopyButton record={record} />
        </div>
      );
    },
  },
];

export const individualDetailsColumns = (showModal: (record: any) => void) => [
  {
    title: 'Started On',
    dataIndex: 'statusUpdatedAt',
    key: 'statusUpdatedAt',
    render: (text: string) => format(new Date(text), 'dd/MM/yyyy - hh:mm a'),
  },
  {
    title: 'Assigned To',
    dataIndex: 'assignedToUser',
    key: 'assignedToUser',
    render: (data: any) => {
      return data.userName ?? '-';
    },
  },
  {
    title: 'Associated Entity',
    dataIndex: 'associatedEntity',
    key: 'associatedEntity',
    render: (text: any) => (text ? text.map((item: any) => item.entityName) : 'None'),
  },

  {
    title: 'Verification Status',
    dataIndex: 'status',
    key: 'status',
    width: '20%',
    render: (status: string) => {
      return <StatusTag status={status} />;
    },
  },

  {
    title: 'Last Status Update',
    dataIndex: 'statusUpdatedAt',
    key: 'statusUpdatedAt',
    width: '20%',
    render: (text: string) => format(new Date(text), 'dd/MM/yyyy - hh:mm a'),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (text: string, record: any) => {
      return (
        <div className="flex gap-2.5 text-gray-700">
          <Tooltip title="Edit Verification Status" placement="top">
            <BaseButton
              className="secondary-button min-h-8 min-w-8 p-0"
              onClick={() => showModal(record)}
            >
              <EditIcon03 className="h-4 w-4" />
            </BaseButton>
          </Tooltip>

          <RedirectButton record={record} />

          <LinkCopyButton record={record} />
        </div>
      );
    },
  },
];

export const companyDrawerIndividualColumns = [
  {
    title: 'Individual Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    render: (record: any) => (
      <div className="overflow-hidden whitespace-nowrap text-ellipsis text-gray-900 font-medium">
        {getFullName(record?.firstName, record?.lastName) ?? ''}
      </div>
    ),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: '20%',

    render: (address: any) => {
      if (!address) return '-';
      return (
        <div className="overflow-hidden whitespace-nowrap text-ellipsis min-w-40 max-w-full ">
          {address.street || ''}, {address.city || ''}, {address.state || ''},
          {address.postcode || ''}
        </div>
      );
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role: string) => {
      return role ?? '-';
    },
  },
  {
    title: 'Total Ownership',
    dataIndex: 'ownership',
    key: 'ownership',
    render: (text: any) => {
      if (!text) return '-';
      const formattedText = parseFloat(text) === 100 ? '100%' : `${parseFloat(text)}%`;
      return formattedText;
    },
  },
];

export const auditLogColumns = [
  {
    title: '',
    dataIndex: 'eventType',
    key: 'eventType',
    width: '3%',
    render: (data: string) => {
      return (
        (
          <div className="font-semibold text-gray-900">
            {data !== 'Verified' ? (
              <SettingsIcon className="text-primary-600 h-4" />
            ) : (
              <UserIcon className="text-success-600 h-4" />
            )}
          </div>
        ) ?? '-'
      );
    },
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: '18%',
    render: (data: string) => {
      const truncatedDate = data?.split('.')[0] + '';
      return (
        (
          <div className="text-gray-800 font-normal">
            {format(new Date(truncatedDate), 'dd/MM/yyyy - hh:mm a')}
          </div>
        ) ?? '-'
      );
    },
  },
  {
    title: 'Action',
    dataIndex: 'detail',
    key: 'detail',
    render: (data: string) => {
      const renderWithTags = (text: string) => {
        const result: React.ReactNode[] = [];
        const words = text.split(' ');

        words.forEach((word, index) => {
          const match = actionTypeStatus.find((option) => option.label === word);

          if (match) {
            result.push(<StatusTag status={match.value} key={`${word}-${index}`} />);
          } else {
            result.push(<span key={`${word}-${index}`}>{word} </span>);
          }
        });

        return result;
      };

      return <div className="text-gray-800 font-normal">{renderWithTags(data)}</div>;
    },
  },
];

export const billingColumns = [
  {
    title: 'Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '18%',
    render: (data: string) => {
      if (!data) return '-';

      return (
        <div className="text-gray-800 font-normal">{format(data, "dd'/'MM/yyyy - hh:mm a")}</div>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'entityName',
    key: 'entityName',
    width: '20%',
    render: (data: string) => {
      return <div className="text-gray-800 font-normal">{data}</div>;
    },
  },
  {
    title: 'Entity Type',
    dataIndex: 'entityType',
    key: 'entityType',
    render: (data: string) => {
      return <div className="text-gray-800 font-normal">{data}</div>;
    },
  },
  {
    title: 'UBO',
    dataIndex: 'uboFlowCount',
    key: 'uboFlowCount',
    render: (data: string = '0') => {
      return <div className="text-gray-800 font-normal">{data}</div>;
    },
  },
  {
    title: 'KYC',
    dataIndex: 'kycFlowCount',
    key: 'kycFlowCount',
    render: (data: string = '0') => {
      return <div className="text-gray-800 font-normal">{data}</div>;
    },
  },
  {
    title: 'Client',
    dataIndex: 'client',
    hidden: getCookieValue('roleId') === 4, //User is guest
    key: 'client',
    render: (data: string = '-') => {
      return <div className="text-gray-800 font-normal">{data}</div>;
    },
  },
  //TODO Uncomment when AML and DVS are implemented
  // {
  //   title: 'AML',
  //   dataIndex: 'aml',
  //   key: 'aml',
  //   render: (data: string = '0') => {
  //     return <div className="text-gray-800 font-normal">{data}</div>;
  //   },
  // },
  // {
  //   title: 'DVS',
  //   dataIndex: 'dvs',
  //   key: 'dvs',
  //   render: (data: string = '0') => {
  //     return <div className="text-gray-800 font-normal">{data}</div>;
  //   },
  // },
];

export const existingEntityDrawerColumns = [
  {
    title: 'Individual Name',
    dataIndex: '',
    key: '',
    width: '30%',
    render: (record: any) => (
      <div className="w-auto text-gray-900 font-medium flex flex-col">
        {getFullName(record?.firstName, record?.lastName) ?? ''}
        <span className="text-gray-500 text-xs">{record?.email}</span>
      </div>
    ),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: '30%',

    render: (address: any) => {
      if (!address) return '-';
      return (
        <div className="overflow-hidden whitespace-nowrap text-ellipsis min-w-40 max-w-full ">
          {address.street || ''}, {address.city || ''}, {address.state || ''},
          {address.postcode || ''}
        </div>
      );
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: '20%',

    render: (role: string) => {
      return role ?? '-';
    },
  },
  {
    title: 'Total Ownership',
    dataIndex: 'ownershipPercentage',
    key: 'ownershipPercentage',
    width: '10%',

    render: (text: any) => {
      if (!text) return '-';
      const formattedText = parseFloat(text) === 100 ? '100%' : `${parseFloat(text)}%`;
      return formattedText;
    },
  },
];

export const actionTypeStatus = [
  { label: 'All', value: 'all' },
  { label: 'Created', value: 'Provider' },
  { label: 'Expired', value: 'Expired' },
  { label: 'Verified', value: 'Verified' },
  { label: 'Submitted', value: 'Submitted' },
  { label: 'Error', value: 'ReviewKyc' },
  { label: 'Initial', value: 'NotVerified' },
  { label: 'ManuallyApproved', value: 'ManuallyApproved' },
  { label: 'ManuallyRejected', value: 'ManuallyRejected' },
];
