import Cookies from 'universal-cookie';
import { isLocalhost } from './utils';

export const getRefreshToken = (): string | null => localStorage.getItem('refreshToken');
export const getUserId = (): string | null => localStorage.getItem('userId');
export const getAccessToken = (): string | null => localStorage.getItem('accessToken');

export const setAccessToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};
export const setRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
};

export const clearToken = (): void => {
  localStorage.removeItem('accessToken');
};

export const getCookieValue = (cookieValue: string) => {
  const cookies = new Cookies();

  const userValue = cookies.get(cookieValue);

  return userValue;
};

export const complianceAppHost = process.env.REACT_APP_LEGACY_COMPLIANCE_APP_HOST;

export const navigateToLink = isLocalhost()
  ? `http://compliance.localhost.com:3000/#`
  : complianceAppHost;

export const navigateToLegacyApp = (relativePath: string) => {
  window.location.href = `${navigateToLink}/${relativePath}`;
};

export const navigateToLoginLegacyApp = () => {
  window.location.href = `${navigateToLink}/login`;
};
