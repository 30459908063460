import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';
import { store } from './app-redux/store';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import 'antd/dist/reset.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </Provider>
);

reportWebVitals();
