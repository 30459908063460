import { ReactNode } from 'react';
import AlertCircle from '@untitled-ui/icons-react/build/esm/AlertCircle';
import Check from '@untitled-ui/icons-react/build/esm/Check';
import Clock from '@untitled-ui/icons-react/build/esm/Clock';
import UsersPlus from '@untitled-ui/icons-react/build/esm/UserX01';
import XCircle from '@untitled-ui/icons-react/build/esm/XCircle';

interface StatusMap {
  [key: string]: {
    tailwindClass: string;
    text: string;
    icon: ReactNode;
  };
}

export const statusMap: StatusMap = {
  InProgress: {
    tailwindClass: 'bg-blue-100 text-blue-600',
    text: 'In Progress',
    icon: <Clock className="h-3 w-3" />,
  },
  ManuallyRejected: {
    tailwindClass: 'bg-error-50 text-error-600',
    text: 'Manually Rejected',
    icon: <XCircle className="h-3 w-3" />,
  },
  PepMatch: {
    tailwindClass: 'bg-warning-50 text-warning-700',
    text: 'PEP Match',
    icon: <AlertCircle className="h-3.5 w-3.5" />,
  },
  Verified: {
    tailwindClass: 'bg-success-50 text-success-700',
    text: 'Passed',
    icon: <Check className="h-3 w-3" />,
  },
  NotVerified: {
    tailwindClass: 'bg-gray-200 text-gray-600 h-[22px]',
    text: 'Not Verified',
    icon: <UsersPlus className="h-3 w-3" />,
  },
  Initial: {
    tailwindClass: 'bg-gray-200 text-gray-600 h-[22px]',
    text: 'Not Verified',
    icon: <UsersPlus className="h-3 w-3" />,
  },
  Expired: {
    tailwindClass: 'bg-gray-100 text-gray-600 h-[22px]',
    text: 'Expired',
    icon: <Clock className="h-3 w-3" />,
  },
  ManuallyApproved: {
    tailwindClass: 'bg-success-50 text-success-700',
    text: 'Manually Passed',
    icon: <Check className="h-3 w-3" />,
  },
  SanctionsMatch: {
    tailwindClass: 'bg-warning-50 text-warning-700',
    text: 'Sanctions Match',
    icon: <AlertCircle className="h-3.5 w-3.5" />,
  },
  DvsFail: {
    tailwindClass: 'bg-error-50 text-error-600',
    text: 'DVS Fail',
    icon: <XCircle className="h-3 w-3" />,
  },
  KycFail: {
    tailwindClass: 'bg-error-50 text-error-600',
    text: 'KYC Fail',
    icon: <XCircle className="h-3 w-3" />,
  },
  ReviewKyc: {
    tailwindClass: 'bg-warning-50 text-warning-700',
    text: 'Review KYC',
    icon: <AlertCircle className="h-3.5 w-3.5" />,
  },
};
