import { ChangeEvent } from 'react';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import CalendarIcon from '@untitled-ui/icons-react/build/esm/Calendar';
import PercentIcon from '@untitled-ui/icons-react/build/esm/Percent02';

import { DatePicker, Input, Select } from 'antd';
import { endOfToday, isAfter, isBefore } from 'date-fns';
import { formatPhoneNumber } from 'utils';

import './AddTrustIndividualForm.scss';

const { Option } = Select;

const disabledDate = (current: any) => {
  const minDate = new Date(1900, 0, 1);
  return (
    current && (isAfter(current.toDate(), endOfToday()) || isBefore(current.toDate(), minDate))
  );
};

const formFields = [
  {
    name: 'firstName',
    label: 'First Name',
    component: Input,
    rules: { required: 'First Name is required' },
  },
  {
    name: 'middleName',
    label: 'Middle Name',
    component: Input,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    component: Input,
    rules: { required: 'Last Name is required' },
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    component: DatePicker,
    placeholder: '',
    suffixIcon: (
      <div className="border-l border-gray-300 h-10 flex items-center mt-0.5">
        <CalendarIcon className="text-gray-700 h-5 w-5 ml-2.5" />
      </div>
    ),
    rules: { required: 'Field required' },
    disableddate: disabledDate,
  },
  {
    name: 'email',
    label: 'Email',
    component: Input,
    rules: {
      required: 'Field required',
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address',
      },
    },
  },
  {
    name: 'mobilePhone',
    label: 'Mobile Phone',
    component: Input,
    maxLength: 10,
    rules: {
      required: 'Mobile phone is required',
      // pattern: {
      //   value: /^(04|05)[0-9]{8}$/,
      //   message: '04 XX XXX XXX or 05 XX XXX XXX',
      // },
    },
  },
  {
    name: 'ownership',
    label: 'Ownership',
    component: Input,
    rules: { required: 'Ownership is required' },
    prefix: <PercentIcon className="text-gray-700 h-4 w-4" />,
    type: 'text',
  },
  {
    name: 'role',
    label: 'Role',
    component: Select,
    rules: { required: 'Role is required' },
    isSelect: true,
    placeholder: <div className="text-gray-900 font-semibold">Select</div>,
  },
];

interface IndividualFormFieldsProps {
  control: any;
  errors: any;
  roleOptions?: any;
  formIndex?: number;
  setValue: UseFormSetValue<FieldValues>;
}

export const IndividualFormFields = ({
  control,
  errors,
  roleOptions,
  formIndex = 1,
  setValue,
}: IndividualFormFieldsProps) => {

  return (
    <div className="flex flex-wrap items-center justify-center w-full gap-5 bg-gray-100 p-[14px] rounded-lg mt-4">
      {formFields?.map((field) => {
        const isRequired = field.rules?.required;
        const fieldName = `${field.name}_${formIndex}`;

        return (
          <div key={fieldName}>
            <label className="block mb-1 text-gray-600 font-semibold text-xs">
              {field.label} {isRequired && '*'}
            </label>

            <Controller
              name={fieldName}
              control={control}
              rules={field.rules}
              render={({ field: controllerField }) => {
                const Component: React.ElementType = field.isSelect ? Select : field.component;

                const ownershipStyle =
                  field.name === 'ownership' || field.name === 'role'
                    ? 'min-w-[302px]'
                    : 'min-w-[194px]';

                return (
                  <Component
                    {...controllerField}
                    prefix={field.prefix}
                    type={field.type}
                    placeholder={field.placeholder}
                    suffixicon={field.suffixIcon}
                    className={`h-10 ${ownershipStyle} text-gray-900 font-medium`}
                    status={errors[fieldName] ? 'error' : ''}
                    disableddate={field.disableddate}
                    rules={field.rules}
                    onChange={(e: ChangeEvent<HTMLInputElement> | any) => {
                      if (field.name === 'mobilePhone') {
                        const formattedValue = formatPhoneNumber(e.target?.value || '');
                        setValue(fieldName, formattedValue);
                      } else if (field.name === 'dateOfBirth') {
                        setValue(fieldName, e || null);
                      } else if (field.name === 'role') {
                        setValue(fieldName, e);
                      } else {
                        setValue(fieldName, e.target?.value || '');
                      }
                    }}

                    // maxLength={field.maxLength ?? 12}
                  >
                    {field.isSelect &&
                      roleOptions.map((role: any) => (
                        <Option key={role.value} value={role.value}>
                          {role.label}
                        </Option>
                      ))}
                  </Component>
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
