import LinkIcon01 from '@untitled-ui/icons-react/build/esm/Link01';

import { notification, Tooltip } from 'antd';
import { BaseButton } from './BaseButton';

interface RecordType {
  outreachUrl: string;
}

interface LinkIconProps {
  record: RecordType;
}

export const LinkCopyButton = ({ record }: LinkIconProps) => {
  const fallbackCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      notification.success({
        message: 'Verification link copied to clipboard.',
        className: 'custom-success-message',
        placement: 'top',
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy verification link to clipboard.',
        className: 'custom-error-message',
        placement: 'top',
      });
    }
    document.body.removeChild(textArea);
  };

  const handleCopyUrl = () => {
    if (!record.outreachUrl) {
      notification.warning({
        message: 'No verification link found.',
        placement: 'top',
      });
      return;
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(record.outreachUrl)
        .then(() => {
          notification.success({
            message: 'Verification link copied to clipboard.',
            className: 'custom-success-message',
            placement: 'top',
          });
        })
        .catch(() => {
          fallbackCopyToClipboard(record.outreachUrl);
        });
    } else {
      fallbackCopyToClipboard(record.outreachUrl);
    }
  };

  return (
    <Tooltip title="Copy Verification Link">
      <BaseButton
        className="secondary-button min-h-8 min-w-8 "
        icon={<LinkIcon01 className="h-4 w-4" />}
        onClick={handleCopyUrl}
      ></BaseButton>
    </Tooltip>
  );
};
