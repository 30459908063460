import DeleteIcon from '@untitled-ui/icons-react/build/esm/Trash03';

import { BaseButton } from './BaseButton';

interface DeleteButtonProps {
  onClick?: (event: any) => void;
  areOptionsVisible?: boolean;
  disabled?: boolean;
}

export const DeleteButton = ({ onClick, areOptionsVisible, disabled }: DeleteButtonProps) => {
  return (
    <BaseButton
      onClick={onClick}
      className="secondary-button min-h-[36px] min-w-[36px] p-0"
      hidden={areOptionsVisible}
      disabled={disabled}
    >
      <DeleteIcon className="h-5 w-5" />
    </BaseButton>
  );
};
