import { ReactNode } from 'react';

import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import { twMerge } from 'tailwind-merge';

import './BaseButton.scss';

interface BaseButtonProps extends AntButtonProps {
  className?: string;
  icon?: ReactNode;
  variant?: 'filter' | 'default';
}

export const BaseButton = ({
  className = '',
  icon,
  variant = 'default',
  ...props
}: BaseButtonProps) => {
  const loadingButtonStyle = props.loading ? 'bg-primary-50 text-primary-600' : '';

  const variantStyles =
    variant === 'filter'
      ? 'border border-grayModern-300 hover:bg-gray-50 border-r-[1px] border-r-grayModern-300'
      : '';

  return (
    <AntButton
      className={twMerge(
        'min-h-10 min-w-[170px] text-gray-700 font-semibold',
        className,
        loadingButtonStyle,
        variantStyles
      )}
      icon={props.loading ? null : icon}
      {...props}
    >
      {props.loading ? 'Checking...' : props.children}
    </AntButton>
  );
};
